:root {
  --primaryColor: #71db77;
  --altPrimaryColor: #050d21;
  --greyP: #a2a6ad;
  --fontRadikalBold: "Radikal-bd";
  --fontGothamRG: "Gotham-rg";
  --fontRadikal: "Radikal-rg";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
  --fontUbuntuMD: "Ubuntu-Medium";
  --fontUbuntuBD: "Ubuntu-Bold";
  --fontAllianceExtra: "Alliance-ExtraBold";
}

.add-wrapper {
  .add-header {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
      url("../../assets/img/dash_2.jpg") center center;
    background-size: cover;
  }

  header {
    min-height: 50vh;

    display: flex;
    flex-direction: column;
    background-position-x: 100%;
    padding: 12vh 5vw 0vh 5vw;

    @media (min-width: 993px) {
      // border-radius: 5vh;
      // margin: 2vh 4vw;
    }
    p {
      color: #f6f6f6;
    }
    .main-head {
      padding-top: 10vh;
      @media (max-width: 770px) {
        padding-top: 13vh;
      }
    }
    .header-img {
      width: 11.8rem;
      // border-radius: 17px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 6vh;
      line-height: 1.3;
      // margin-bottom: 2rem;
      margin-top: 4rem;
      margin-bottom: 9vh;
      font-family: "Radikal-bd";
      text-align: center;
    }
  }

  .btn {
    box-shadow: 2px 2px 2px 2px rgba(159, 162, 176, 0.3);
    padding: 0.5rem 2.8rem !important;
    stroke: none;
    transition: 0.3s ease all;
    &:hover {
      transform: translateY(-2px);
    }
  }

  .btn-primary {
    background: var(--primaryColor);
    padding: 0;
    border: none;
    border-radius: 24px;
  }

  .search-box {
    background-color: #fafafa;
    border-radius: 3vh;
    margin-top: -3vh;
    padding: 3vh 4vw 3vh 4vw;
  }

  .click {
    cursor: pointer;
  }

  .text-primary {
    color: var(--primaryColor) !important;
  }

  .small-footer {
    p {
      color: white;
      a {
        color: white;
      }
    }
  }

  #property-listing {
    .form-control {
      color: var(--altPrimaryColor) !important;
      font-family: var(--fontUbuntuMD) !important;
    }

    .search-fields {
      min-height: 35px;
      padding: 0;
      height: 35px;
      padding-left: 4px;
      box-shadow: none;
      border: 1px solid #e8e8e8;
      background: #fff;
      border-radius: 3px;
      cursor: pointer;
    }

    label {
      font-family: var(--fontUbuntuRG);
    }
  }

  .file-picker {
    #fileUpload {
      display: none;
    }
    .picker {
      cursor: pointer;
      min-height: 120px;
      min-width: 50vw;
      width: 100%;
      border: var(--primaryColor) dotted;
      border-radius: 16px;
      border-width: 2px;
      align-items: center;
      justify-content: center;
    }
  }

  .img-upload {
    border-radius: 7px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
