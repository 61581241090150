:root {
  --primaryColor: #71db77;
  --altPrimaryColor: #050d21;
  --greyP: #a2a6ad;
  --fontRadikalBold: "Radikal-bd";
  --fontGothamRG: "Gotham-rg";
  --fontRadikal: "Radikal-rg";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
  --fontUbuntuMD: "Ubuntu-Medium";
  --fontUbuntuBD: "Ubuntu-Bold";
  --fontAllianceExtra: "Alliance-ExtraBold";
}

.dash-wrapper {
  .dash-header {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
      url("../../assets/img/dash_bg.jpg") center center;
    background-size: cover;
  }

  header {
    min-height: 50vh;

    display: flex;
    flex-direction: column;
    background-position-x: 100%;
    padding: 12vh 5vw 0vh 5vw;

    @media (min-width: 993px) {
      // border-radius: 5vh;
      // margin: 2vh 4vw;
    }
    p {
      color: #f6f6f6;
    }
    .main-head {
      padding-top: 10vh;
      @media (max-width: 770px) {
        padding-top: 13vh;
      }
    }
    .header-img {
      width: 11.8rem;
      // border-radius: 17px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 6.5vh;
      line-height: 1.3;
      // margin-bottom: 2rem;
      margin-top: 4rem;
      margin-bottom: 9vh;
      font-family: "Radikal-bd";
      text-align: center;
    }
  }

  .btn {
    box-shadow: 2px 2px 2px 2px rgba(159, 162, 176, 0.3);
    padding: 0.5rem 2.8rem !important;
    stroke: none;
    transition: 0.3s ease all;
    &:hover {
      transform: translateY(-2px);
    }
  }

  .btn-primary {
    background: var(--primaryColor);
    padding: 0;
    border: none;
    border-radius: 24px;
  }

  .search-box {
    background-color: #fafafa;
    border-radius: 3vh;
    margin-top: -3vh;
    padding: 3vh 4vw 3vh 4vw;
  }
  /** OPTION BAR **/
  .option-bar {
    border-radius: 0;
    height: 55px;
    margin-bottom: 15px;
    // box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    .heading {
      font-size: 17px;
    }
    .heading-icon {
      background: var(--primaryColor);
      width: 55px;
      color: #fff;
      text-align: center;
      margin-right: 20px;
      display: inline-block;
      border-radius: 0;
      position: relative;
      .icon-design {
        color: var(--primaryColor);
        font-size: 20px;
        position: absolute;
        right: -7px;
        top: 16px;
      }
    }

    h4 {
      line-height: 55px;
      display: inline-block;
      padding-right: 0;
      border-radius: 0;
      margin: 0;
    }

    .subtitle {
      margin-bottom: 20px;
      font-size: 14px;
      color: #9c9c9c;
    }
    .search-area {
      width: 170px;
      margin-top: 10px;
      float: right;
    }

    /** PROPERTY BOX 5 **/
    .property-box-5 {
      position: relative;
      margin-bottom: 30px;
      overflow: hidden;
      background: #fff;
      //   display: none;

      .property-img {
        position: relative;
        display: block;
        overflow: hidden;
        .price {
          font-weight: 400;
          font-size: 15px;
          margin: 0;
          color: #fff;
        }
      }
      img {
        width: 100%;
      }

      .button,
      input[type="submit"] {
        font-size: 11px;
        font-weight: 500;
        padding: 6px 14px 4px;
        border-radius: 2px;
        color: white;
        background: rgba(0, 0, 0, 0.7);
        display: inline-block;
        letter-spacing: 1px;
      }
      .tag.featured {
        left: 30px;
        margin-top: 15px;
        z-index: 20;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .tag {
        position: absolute;
      }
      .price-ratings-box {
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 20;
        text-align: right;
        .price {
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          margin: 0;
        }
        .ratings {
          font-size: 10px;
          color: #ffc12b;
        }
      }

      .detail {
        padding: 20px 30px 20px 25px;
        .title {
          font-size: 19px;
          margin-bottom: 10px;
        }
        a:hover {
          color: #4a4747;
        }
      }
    }
  }

  .click {
    cursor: pointer;
  }

  .text-primary {
    color: var(--primaryColor) !important;
  }

  .small-footer {
    p {
      color: white;
      a {
        color: white;
      }
    }
  }
}

/*------------------------------------------------------------------
[Main Stylesheet]

Project:    Dream Home
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    *. Top Header / .top-header
    *. Main Header / .main-header
    *. Home Page Banner (Main Home) / .banner
    *. Video Banner / .banner_video_bg
    *. Banner Search Box / .search-area
    *. Recent Properties / .recent-properties
    *. Featured Properties / .featured-properties
    *. Property Featured (Single Page)  / .features-opions
    *. Property Amenities  / .amenities-box
    *. Property Comparison / .comparison
    *. Property Compare Area / .compare-properties
    *. Property Tabbing / .tabbing
    *. Property Heading / .heading-properties
    *. Popular Places / .most-popular-places
    *. Application Counter / .counters
    *. Partner / .partners-block
    *. Testimonials / .testimonial
    *. Testimonials Style 2/ .testimonial-2
    *. Map Content /.map-content
    *. Map Content Sidebar /.map-content-sidebar
    *. Make Sticky Header / .do-sticky
    *. Single Page Banner / .sub-banner
    *. Properties List / .properties-list-rightside
    *. Single Property Details / .properties-details-page
    *. Services Area /.services
    *. Services Info /.services-info
    *. Listing Page Sidebar /.sidebar
    *. Social List /.social-list
    *. Typography /.typography
    *. Pricing Table /.pricing-tables
    *. Agent Page /.agent
    *. Agent Contact Form /.agent-widget
    *. Properties Comments /.comments-section
    *. Partner Area /.partner
    *. Help Center /.helping-center
    *. Shopping Cart /.shop-cart
    *. Shopping Checkout /.shop-checkout
    *. Shopping List /.shop-list
    *. Shopping Details /.shop-single
    *. Specifications /.specifications
    *. Submit Property /.submit-property
    *. Profile /.user-page
    *. My Properties Box /.my-properties
    *. Submit Property /.submit-property
    *. Blog /.blog-section
    *. Contact Page /.contact-1
    *. About Page /.about-us
    *. Agent Details Page /.agent-detail
    *. Coming Soon Page /.coming-soon
    *. Contact MAP /#contactMap
    *. FAQ Section /.faq
    *. Photo Gallery /.photo-gallery
    *. Signup/Login Wrapper /.register-page
    *. Full Page Search /#full-page-search
    *. 404 Section /.pages-404
    *. Brand Section /.view-section
    *. Pagination /.pagination
    *. Main Footer / .footer
    *. Footer Item / .footer-item
-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/

body {
  font-family: "Roboto", sans-serif;
  background: #fafafa;
  color: #535353;
}

.img-fluid {
  max-width: 100% !important;
  height: auto;
}

.page_loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: url(../../assets/img/loader.gif) center no-repeat #fff;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #535353;
}

a {
  color: #37404d;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #535353;
}

.form-control:focus {
  box-shadow: none;
}

p {
  font-size: 14px;
  line-height: 26px;
  color: #535353;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #37404d;
  font-family: "Poppins", sans-serif;
}

.heading-2 {
  margin-bottom: 30px;
}

.heading-2 h4 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 25px;
}

.heading-2 p {
  font-size: 15px;
  font-weight: 400;
}

.bordered-right {
  border-right: 1px solid rgba(22, 24, 27, 0.12);
}

.col-pad {
  padding: 0 0;
}

.col-pad-30 {
  padding: 0 30px;
}

.pad-minus-lr-15 {
  padding-top: 50px;
}

.mbl {
  margin: 0 0 30px 20px;
}

.mrb {
  margin: 0 20px 50px 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-30 {
  margin-bottom: 30px;
}

.hidden {
  display: none;
}

.form-inline .btn,
.form-inline .btn:active {
  padding: 0 17px;
  font-size: 15px;
  background: none;
  border: solid 1px transparent;
  color: #212121;
}

.form-inline .btn-2 {
  color: #fff;
}

.form-inline .btn:hover {
  background: none;
  border: solid 1px transparent;
}

.form-inline a {
  padding: 0 17px;
}

.btn {
  font-family: "Poppins", sans-serif;
  border-radius: 3px;
}

.btn-black {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #3a3a3a;
}

.btn-black:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-round {
  border-radius: 30px;
}

.delay-02s {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.delay-03s {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.delay-04s {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.delay-05s {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.delay-06s {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.delay-07s {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}

.delay-08s {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

.delay-09s {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}

.delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.delay-12s {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

.delay-15s {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}

.delay-17s {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
}

.delay-20s {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

input[type="checkbox"]:checked + label:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  color: #f3f3f3;
  text-align: center;
  line-height: 15px;
}

.form-check label,
.radio label {
  min-height: 20px;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.form-check label {
  display: inline-block;
  position: relative;
  padding-left: 10px;
}

.form-check label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 0px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-check label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox-theme input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox {
  margin: 3px 0;
}

.map-content-sidebar .checkbox {
  margin: 3px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  color: #535353;
  font-size: 13px;
  line-height: 22px;
  cursor: pointer;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 2px solid #cccccc;
  border-radius: 10%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-content-box .checkbox label::before {
  border: none;
}

.checkbox-theme input[type="checkbox"]:checked + label::before {
  background-color: #fff;
}

input[type="checkbox"]:checked + label:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 16px;
  font-weight: 300;
}

.form-group .form-check {
  margin-left: 20px;
}

#page_scroller {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  cursor: pointer;
  color: #fff;
  padding: 10px 11px;
  font-size: 12px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/*Custom Anmation*/
.bgburns .slides li:nth-of-type(odd) .slide-background {
  animation: bgburnsEast 25s linear infinite alternate;
  -webkit-animation: bgburnsEast 25s linear infinite alternate;
}

.bgburns .slides li:nth-of-type(even) .slide-background {
  animation: bgburnsWest 25s linear infinite alternate;
  -webkit-animation: bgburnsWest 25s linear infinite alternate;
}

@keyframes bgburnsEast {
  from {
    transform: scale(1) translateX(0);
    -webkit-transform: scale(1) translateX(0);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}

@-webkit-keyframes bgburnsEast {
  from {
    transform: scale(1) translateX(0);
    -webkit-transform: scale(1) translateX(0);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}

@keyframes bgburnsWest {
  from {
    transform: scale(1) translateX(0);
    -webkit-transform: scale(1) translateX(0);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}

@-webkit-keyframes bgburnsWest {
  from {
    transform: scale(1) translateX(0);
    -webkit-transform: scale(1) translateX(0);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}

@media all and (max-width: 767px) {
  .flex-direction-nav {
    display: none;
  }

  .bgburns .slides li .slide-background {
    -webkit-animation: none !important;
    animation: none !important;
  }
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-left {
  text-align: left;
}

/** Option Panel **/
.option-panel {
  z-index: 9999;
  width: 215px;
  position: fixed;
  right: 0;
  top: 20%;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 20px 15px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.option-panel-collased {
  right: -215px;
}

.option-panel h2 {
  font-size: 13px;
  margin: 0 0 0 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
}

.option-panel .color-plate {
  width: 24px;
  height: 24px;
  float: left;
  margin: 3px;
  border-radius: 3px;
  cursor: pointer;
}

/** WEB SITE COLOR **/
.default-plate {
  background: #2048bb;
}

.blue-plate {
  background: #95c41f;
}

.yellow-plate {
  background: #ffb400;
}

.green-light-plate {
  background: #1abc9c;
}

.red-plate {
  background: #c31c1c;
}

.orange-plate {
  background: #f34b0a;
}

.yellow-light-plate {
  background: #eae328;
}

.green-light-2-plate {
  background: #0dcdbd;
}

.olive-plate {
  background: #b3c211;
}

.purple-plate {
  background: #8e44ad;
}

.blue-light-plate {
  background: #3c9dff;
}

.brown-plate {
  background: #a14c10;
}

.setting-button {
  position: absolute;
  font-size: 16px;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  left: -50px;
  color: #fff;
  top: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}
/** Option Panel **/

/** OVERVIEW BGI **/
.overview-bgi {
  position: relative;
}

.overview-bgi:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.overview .btn-border {
  padding: 5px 12px !important;
  border-radius: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}

/** CONTENT AREA **/
.content-area {
  padding-bottom: 70px;
}

.content-area-2 {
  padding: 100px 0 70px;
}

.content-area-3 {
  padding-bottom: 84px;
}

.content-area-4 {
  padding: 100px 0 50px;
}

.content-area-5 {
  padding-bottom: 100px;
}

.content-area-6 {
  padding-bottom: 50px;
}

.content-area-7 {
  padding: 100px 0;
}

.content-area-8 {
  padding-top: 100px;
}

.content-area-9 {
  padding: 50px 0;
}

.content-area-10 {
  padding: 100px 0 40px;
}

.content-area-11 {
  padding: 100px 0 70px;
}

.content-area-12 {
  padding-bottom: 50px;
}

.content-area-13 {
  padding: 100px 0 50px;
}

.content-area-14 {
  padding: 100px 0 80px;
}

.content-area-15 {
  padding: 100px 0 40px;
}

.content-area-16 {
  padding: 100px 0 70px;
}

.content-area-17 {
  margin-bottom: 70px;
}

.content-area-18 {
  padding: 100px 0 20px;
}

.content-area-19 {
  padding: 184px 0 70px;
}

.content-area-20 {
  padding: 100px 0;
}

/** BTN COLOR **/
.btn-border-black {
  color: #000;
  border: solid 2px #fff;
}

.btn-border-white {
  color: #fff;
  border: solid 2px #fff;
}

.btn-border-white:hover {
  color: #fff;
  border: solid 2px #fff;
}

.btn-black {
  background: #000;
  border: solid 2px #000;
  color: #fff;
}

/** BTN **/
.btn-sm {
  padding: 7px 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.btn-md {
  cursor: pointer;
  padding: 12px 30px 10px;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 3px;
}

.btn-lg {
  cursor: pointer;
  padding: 14px 35px 12px;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.btn-white {
  cursor: pointer;
  padding: 12px 30px 10px;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 3px;
  background: #fff;
}

.btn-md-round {
  border-radius: 30px;
  border-color: #4a90e2;
}

.btn:focus,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none;
}

.signup-link {
  margin-top: 22px;
  margin-left: 10px;
  padding: 7px 20px;
  text-align: center;
}

.btn-white-sm-outline {
  color: #fff;
  background: transparent;
  border: solid 2px #fff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 18px;
  font-size: 12px;
}

.btn-white-sm-outline:hover {
  color: #fff;
  padding: 6px 19px;
}

.btn-white-lg-outline {
  color: #fff;
  background: transparent;
  border: solid 2px #fff;
  text-transform: uppercase;
  font-weight: 400;
  padding: 12px 33px 10px;
  font-size: 12px;
}

.btn-white-lg-outline:hover {
  color: #fff;
}

.btn-theme-sm-outline {
  color: #fff;
  border: solid 2px #fff;
  background: transparent;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 18px;
  font-size: 12px;
}

.btn-theme-sm-outline:hover {
  color: #fff;
  padding: 5px 18px;
}

/** SOCIAL MEDIA **/
.facebook:hover {
  color: #3b589e !important;
}

.twitter:hover {
  color: #56d7fe !important;
}

.linkedin:hover {
  color: #1c82ca !important;
}

.google:hover {
  color: #dc4e41 !important;
}

.rss:hover {
  color: #e78733 !important;
}

.instagram:hover {
  color: #fb3958 !important;
}

/** SOCIAL MEDIA BG **/
.facebook-bg {
  background: #4867aa;
}

.facebook-bg:hover {
  background: #3a5795;
}

.twitter-bg {
  background: #56d7fe;
}

.twitter-bg:hover {
  background: #2abced;
}

.google-bg {
  background: #dc4e41;
}

.google-bg:hover {
  background: #c83a2e;
}

.rss-bg {
  background: #e78733;
}

.rss-bg:hover {
  background: #d0782c;
}

.linkedin-bg {
  background: #1c82ca;
}

.linkedin-bg:hover {
  background: #036497;
}

/** MEGAMENU **/
.megamenu-li {
  position: static;
}

.megamenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 15px;
}

.megamenu-area {
  padding: 30px 30px 5px;
  z-index: 2;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.megamenu-title {
  color: #535353;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.megamenu-section {
  margin-bottom: 25px;
}

.megamenu-area .dropdown-item {
  padding: 0px !important;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
}

.megamenu-section p {
  font-size: 14px;
  color: #777;
}

/** TOP HEADER **/
#top-header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #b7b7b7;
  background-color: rgba(0, 0, 0, 0.4);
}

#top-header a {
  color: #fff;
}

.top-header-bg-black {
  background: transparent;
}

.top-header .list-inline {
  padding: 7px 0;
}

.top-header .list-inline a {
  margin-right: 15px;
  font-size: 12px;
  letter-spacing: 1px;
}

.top-header .list-inline a i {
  margin-right: 5px;
}

.top-header .top-social-media li {
  padding: 0 4px;
  height: 40px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
}

.top-header .top-social-media li a {
  color: #fff;
}

#top-header-2 a {
  color: #fff;
}

/** MAIN HEADER **/
#main-header {
  width: 100%;
  background: #fff;
  webkit-transition: height 0.3s ease-out, background 0.3s ease-out,
    box-shadow 0.5s, left 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.5s;
  transition: height 0.3s ease-out, background 0.3s ease-out, box-shadow 0.5s,
    left 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.5s;
  transition: height 0.3s ease-out, background 0.3s ease-out, transform 0.5s,
    box-shadow 0.5s, left 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.3s ease-out, background 0.3s ease-out, transform 0.5s,
    box-shadow 0.5s, left 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.5s;
}

.main-header {
  background: #fff;
}

.main-header .navbar {
  padding: 0;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
  padding: 30px 17px 27px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  border-bottom: solid 2px transparent;
  font-family: "Roboto", sans-serif;
}

/*#main-header .navbar-expand-lg .navbar-nav .nav-link{
    color: #fff;
}*/

.main-header .logo img {
  height: 40px;
}

.main-header .logo {
  padding: 19px 0;
  margin-right: 50px;
  float: left;
}

a {
  transition: all 0.3s ease-in-out;
}

.dropdown-menu {
  min-width: 10rem;
  padding: 0;
  margin: -1px;
  font-size: 13px;
  color: #212529;
  border: 1px solid transparent;
  -webkit-transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
}

.dropdown:hover > .dropdown-menu {
  /*display: block;*/
}

.dropdown .dropdown-item:hover > .dropdown-menu {
  /*display: block;*/
  -webkit-transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
}

.main-header .dropdown-menu a {
  padding: 12px 20px;
  color: #535353;
  transition: all 0.3s ease-in-out;
}

.main-header .dropdown-menu a:hover {
  background: #f5f5f5;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 22px;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
}

.dropdown-submenu:hover > .dropdown-menu {
  /*display: block;*/
  -webkit-transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s,
    -webkit-transform 0.4s ease 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s;
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s,
    visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
  color: #212121;
}

/*
.collapse.show{
    background: #fff;
}
*/

.open-offcanvas {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  height: 100%;
  /*display: none;*/
}

/** BANNER **/
#banner {
  position: relative;
}

.banner {
  font-family: "Poppins", sans-serif;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
}

.banner-bg {
  background: url("../../assets/img/banner-3.jpg");
}

.item-bg {
  /*background: url("../img/banner-1.jpg");*/
  height: 100vh;
  /*animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate;*/
}

.banner img {
  animation: bgburnsEast 25s linear infinite alternate;
  -webkit-animation: bgburnsEast 25s linear infinite alternate;
}

.banner-max-height img {
  max-height: 720px;
}

.banner .slider-mover-left {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: absolute;
  z-index: 5;
  display: inline-block;
  right: -45px;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
}

.banner .slider-mover-right {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: absolute;
  z-index: 5;
  display: inline-block;
  right: 10px;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
}

.banner .slider-mover-right i {
  font-size: 23px;
  line-height: 35px;
  color: #000;
}

.banner .slider-mover-left i {
  font-size: 23px;
  line-height: 35px;
  color: #000;
}

.banner .carousel-content {
  align-self: center !important;
  margin: 0px auto;
  color: #fff;
}

/*.banner .carousel-content-left {
    align-self: center !important;
    max-width: 800px;
    margin-left: 0px;
    text-align: left;
}*/

/*.banner .banner-slider-inner h1,
.banner .banner-slider-inner h2,
.banner .banner-slider-inner h3,
.banner .banner-slider-inner h4,
.banner .banner-slider-inner h5,
.banner .banner-slider-inner h6,
.banner .banner-slider-inner p{
    max-width: 800px;
}*/

.carousel-item .text-right {
  float: right;
}

.banner .banner-slider-inner h1 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: normal;
}

.banner .banner-slider-inner h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: normal;
  text-transform: uppercase;
}

.banner .banner-slider-inner h3 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: normal;
}

.banner .banner-slider-inner .max-w {
  max-width: 490px;
}

.banner .banner-slider-inner p {
  color: #fff;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: 40px;
}

.banner .banner-slider-inner .text-p {
  margin-bottom: 30px;
}

.banner .banner-slider-inner h1 span {
  font-weight: 700;
}

.banner .banner-slider-inner .typed-text {
  font-size: 50px;
  margin-bottom: 30px;
}

.banner .banner-slider-inner {
  display: inline-block;
  text-shadow: none;
  right: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: rgba(36, 42, 53, 0.7);
}

.banner .banner-slider-inner .btn {
  margin: 3px;
}

.banner .carousel-bg .carousel-item {
  height: 100vh;
  background: #3e4a5b;
}

.banner_video_bg {
  height: 100vh;
}

.pattern-overlay {
  /*background-color: rgba(0, 0, 0, 0.35);
    height: 100vh;*/
}

.banner_video_bg .carousel-item {
  height: 100vh;
}

#particles-banner {
  width: 100%;
  height: 100vh;
  background-color: rgba(36, 42, 53, 0.7);
  position: absolute;
}

#particles-banner-wrapper .banner-slider-inner {
  background-color: transparent;
}
/** SUB BANNER **/
.sub-banner {
  background: rgba(0, 0, 0, 0.04) url(../../assets/img/banner-1.jpg) top left
    repeat;
  background-size: cover;
  height: 355px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-color: rgba(36, 42, 53, 0.7);
}

.sub-banner .breadcrumb-area h1 {
  margin: 0 0 15px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 34px;
  letter-spacing: 2px;
}

.sub-banner .breadcrumb-area {
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 48%;
  right: 0;
  left: 0;
  padding: 0 15px;
}

.sub-banner .breadcrumbs li {
  display: inline-block;
  list-style: none;
  font-size: 16px;
}

.sub-banner .breadcrumbs li a {
  color: #fff;
}

.sub-banner .breadcrumbs .active {
  margin-left: 5px;
  color: #fff;
}

.sub-banner .breadcrumbs .active:before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
}

/** MAIN TITLE **/
.main-title {
  text-align: center;
  margin-bottom: 50px;
}

.main-title h1 {
  font-weight: 600;
  color: #37404d;
  font-size: 34px;
  margin-bottom: 15px;
}

.main-title h1 span {
  font-weight: 500;
}

.main-title p {
  font-weight: 300;
  font-size: 19px;
  color: #808080;
  margin-bottom: 0;
}

/** OPTION BAR **/
.option-bar {
  border-radius: 0;
  height: 55px;
  margin-bottom: 15px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.option-bar .heading-icon {
  width: 55px;
  color: #fff;
  text-align: center;
  margin-right: 20px;
  display: inline-block;
  border-radius: 0;
  position: relative;
}

.option-bar h4 {
  line-height: 55px;
  display: inline-block;
  padding-right: 0;
  border-radius: 0;
  margin: 0;
}

.option-bar .heading-icon .icon-design {
  font-size: 20px;
  position: absolute;
  right: -7px;
  top: 16px;
}

.option-bar .heading {
  font-size: 17px;
}

.change-view-btn {
  margin-left: 5px;
  background: transparent;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  text-align: center;
}

.active-view-btn {
  color: #fff !important;
  margin-left: 5px;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

.change-view-btn:hover {
  color: #fff;
}

.option-bar .sorting-options {
  margin: 9px 15px 0 0;
  text-align: right;
  width: 90px;
  float: right;
}

.option-bar .search-area .search-fields {
  min-height: 35px;
  padding: 0;
  height: 35px;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.option-bar .search-area .selectBox-dropdown .selectBox-arrow:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f078";
  line-height: 35px;
  color: #777;
  font-size: 12px;
}

.subtitle {
  margin-bottom: 20px;
  font-size: 14px;
  color: #9c9c9c;
}

.option-bar .search-area {
  width: 170px;
  margin-top: 10px;
  float: right;
}

/** PROPERTY BOX **/
.property-box {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.property-box:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-thumbnail {
  position: relative;
}

.property-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
  content: "";
  z-index: 9;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.9) 0%,
    rgba(35, 35, 37, 0.45) 5%,
    rgba(22, 22, 23, 0) 15%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 4px 4px 0 0;
  opacity: 1;
}

.property-overlay {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-link {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  text-align: center;
  margin: 3px;
  cursor: pointer;
  display: none;
}

.overlay-link:not([href]):not([tabindex]),
.overlay-link:not([href]):not([tabindex]):hover {
  color: #fff;
}

.overlay-link:hover {
  color: #fff;
}

.property-thumbnail:hover .property-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.property-thumbnail:hover .overlay-link {
  display: inline-block;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/** PROPERTY BOX STARTED **/
.property-box {
  background: white;
}

.property-box .property-img {
  position: relative;
  display: block;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

.property-box .property-img img {
  width: 100%;
}

.property-box .detail .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.property-box .detail .title a:hover {
  color: #4a4747;
}

.property-box .button,
input[type="submit"] {
  font-size: 11px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: 2px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  letter-spacing: 1px;
}

.property-box .tag.featured {
  left: 15px;
  margin-top: 15px;
  z-index: 20;
  letter-spacing: 1.5px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px 10px 4px 10px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
}

.property-box .tag {
  position: absolute;
}

.property-box .price-ratings-box {
  color: white;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 20;
  text-align: right;
}

.property-box .price-ratings-box .price {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: #fff;
}

.ratings {
  font-size: 10px;
  color: #ffc12b;
}

.property-box .detail {
  padding: 20px 20px 15px;
}

.property-box .detail .location {
  margin: 0 0 15px;
}

.property-box .detail .location a {
  font-size: 13px;
  color: #535353;
  font-weight: 400;
}

.property-box .detail .location a i {
  margin-right: 5px;
}

.property-box ul {
  margin: 0;
  padding: 0;
}

.property-box ul li {
  list-style: none;
  width: 50%;
  float: left;
  font-weight: 400;
  line-height: 35px;
  font-size: 13px;
}

.property-box ul li i {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.property-box .footer {
  background: none;
  border-top: 1px solid #ebebeb;
  padding: 12px 20px;
  font-size: 13px;
  color: #656565;
}

.property-box .footer a {
  color: #656565;
}

.property-box .footer span {
  float: right;
}

.property-box .footer i {
  margin-right: 5px;
}

/** PROPERTY BOX 2 STARTED **/
.property-box-2 {
  background: #ffffff;
  margin-bottom: 30px;
  border: none;
  border-radius: 0;
}

.property-box-2:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-box-2 img {
  border-radius: 0;
  width: 100%;
}

.property-box-2 .detail {
  padding: 20px 20px;
  text-align: left;
}

.property-box-2 .detail p {
  font-size: 14px;
  margin: 0;
  color: #656565;
}

.property-box-2 .detail h5 {
  margin-bottom: 10px;
}

.property-box-2 .detail h5 a {
  font-size: 18px;
}

.property-box-2 .detail h4 {
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 12px;
}

/** PROPERTY BOX 3 STARTED **/
.property-box-3 {
  margin: 0 0 30px;
}

.property-box-3 .media-body {
  padding-right: 30px;
}

.property-box-3 img {
  width: 130px;
  height: 130px;
  border-radius: 3px;
  margin-right: 20px;
}

.property-box-3 h3 {
  font-size: 19px;
  margin-bottom: 10px;
}

.property-box-3 h5 {
  font-size: 14px;
  font-weight: 500;
}

.property-box-3 p {
  font-size: 14px;
  line-height: 25px;
  margin: 0;
}

/** PROPERTY BOX 4 STARTED **/
.property-box-4 {
  position: relative;
  overflow: hidden;
  color: #fff;
  margin-bottom: 30px;
}

.property-box-4 .property-img img {
  width: 100%;
}

.property-box-4 .text {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 13px;
  letter-spacing: 1px;
  z-index: 20;
}

.property-box-4 .text a {
  color: #fff;
}

/** PROPERTY BOX 5 **/
.property-box-5 {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;
}

.property-box-5:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-box-5 .property-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.property-box-5 .property-img img {
  width: 100%;
}

.property-box-5 .button,
input[type="submit"] {
  font-size: 11px;
  font-weight: 500;
  padding: 6px 14px 4px;
  border-radius: 2px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  letter-spacing: 1px;
}

.property-box-5 .tag.featured {
  left: 30px;
  margin-top: 15px;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.property-box-5 .tag {
  position: absolute;
}

.property-box-5 .price-ratings-box {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 20;
  text-align: right;
}

.property-box-5 .price-ratings-box .price {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.property-box-5 .price-ratings-box .ratings {
  font-size: 10px;
  color: #ffc12b;
}

.property-box-5 .detail {
  padding: 20px 30px 20px 25px;
}

.property-box-5 .detail .title {
  font-size: 19px;
  margin-bottom: 10px;
}

.property-box-5 .detail .title a:hover {
  color: #4a4747;
}

.p-box .detail .title {
  font-size: 22px;
}

.property-box-5 .detail .location {
  margin: 0 0 15px;
}

.property-box-5 .detail .location a {
  font-size: 13px;
  color: #535353;
}

.property-box-5 .detail .location a i {
  margin-right: 5px;
}

.property-box-5 .detail p {
  margin-bottom: 15px;
  line-height: 26px;
  font-size: 14px;
}

.property-box-5 .col-pad {
  padding: 0;
}

.property-box-5 ul li {
  list-style: none;
  margin-right: 25px;
  float: left;
  font-weight: 400;
  font-size: 13px;
}

.property-box-5 ul li:last-child {
  margin: 0;
}

.property-box-5 ul li i {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
}

/** PROPERTY BOX 6 STARTED **/
.property-box-6 {
  background: #fff;
}

.property-box-6 img {
  width: 100%;
}

.property-box-6:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-box-6 .info {
  padding: 0 40px;
}

.property-box-6 ul {
  padding: 0;
  display: inline-block;
}

.property-box-6 .btn {
  margin-top: 15px;
}

.property-box-6 ul li {
  list-style: none;
  color: #666666;
  font-size: 14px;
  line-height: 35px;
}

.property-box-6 ul li i {
  margin-right: 10px;
}

.property-box-6 h3 {
  margin: 0 0 20px;
}

.property-box-6 p {
  margin-bottom: 20px;
}

/** PROPERTY BOX 7 STARTED **/
.property-box-7 {
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-box-7 img {
  width: 100%;
}

.property-box-7:hover {
  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.property-box-7 .detail {
  padding: 20px 20px;
  background: #fff;
}

.property-box-7 .detail .title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.property-box-7 .detail .title a:hover {
  color: #4a4747;
}

.property-box-7 .detail .location {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

.property-box-7 .detail .location a {
  color: #535353;
}

.property-box-7 .detail .location i {
  margin-right: 5px;
}

.property-box-7 .facilities-list {
  padding: 15px 20px;
  background: #f7f7f7;
}

.property-box-7 .facilities-list li {
  list-style: none;
  float: left;
  font-weight: 400;
  line-height: 30px;
  font-size: 14px;
  margin-right: 25px;
}

.property-box-7 .facilities-list li span {
  display: table;
  font-weight: 500;
}

.property-box-7 .facilities-list li:last-child {
  margin: 0;
}

.property-box-7 .footer {
  background: #fff;
  padding: 0;
}

.property-box-7 .footer p {
  line-height: 48px;
  margin: 0 0 0 20px;
}

.property-box-7 .footer p i {
  margin-right: 5px;
}

.property-box-7 .footer ul li {
  display: inline-block;
  width: 48px;
  margin: 0 0 0 -4px;
}

.property-box-7 .footer ul li a {
  display: block;
  font-size: 18px;
  height: inherit;
  line-height: 48px;
  text-align: center;
  width: 48px;
  border-left: 1px solid #e5e5e5;
  text-decoration: none;
  color: #535353;
}

.property-box-7 .footer ul li a:hover {
  text-decoration: none;
}

.listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.listing-badges .featured {
  float: left;
  transform: rotate(-45deg);
  left: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 12px;
  margin: 0;
  padding: 7px 10px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #fff;
  box-shadow: 1px 2px 3px 0 rgba(2, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
}

.property-box-7 .price-box {
  color: #fff;
  text-align: right;
  font-size: 15px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 20;
}

.property-box-7 .price-box span {
  font-weight: 500;
}

.property-thumbnail:before {
  content: "";
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.9) 0%,
    rgba(35, 35, 37, 0.45) 35%,
    rgba(22, 22, 23, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 4px 4px 0 0;
  opacity: 1;
}

/** PROPERTY BOX 8 STARTED **/
.property-box-8 .detail {
  position: relative;
  float: left;
  width: 90%;
  margin: -30px 5% 30px 5%;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.property-box-8 .detail .heading {
  padding: 20px;
}

.property-box-8 .detail h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.property-box-8 .date-box {
  position: absolute;
  top: 15px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  right: 30px;
  letter-spacing: 1.5px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px 10px 4px 10px;
  border-radius: 2px;
  text-transform: uppercase;
}

.property-box-8 .detail .location {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

.property-box-8 .detail .location a {
  color: #535353;
}

.property-box-8 .properties-listing {
  text-align: center;
  border-top: 1px solid #ebebeb;
}

.property-box-8 .properties-listing span {
  color: #5a5959;
  float: left;
  line-height: 40px;
  text-align: center;
  width: 33.3%;
  font-size: 14px;
  border-right: 1px solid #ebebeb;
}

.property-box-8 .properties-listing span:last-child {
  border-right: 1px solid transparent;
}

/** USER PROFILE STARTED **/
.user-profile-box {
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin: 0 auto 50px;
}

.user-page .heading {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 22px;
}

.user-profile-box .header {
  padding: 30px 20px 120px;
  text-align: center;
  position: relative;
  border: none;
  margin: 0;
  background: rgba(0, 0, 0, 0.04) url(../../assets/img/banner-3.jpg) top left
    repeat;
  background-size: cover;
  color: #efefef;
}

.user-profile-box .header h2 {
  margin: 0 0 8px;
  color: #fff;
  font-size: 24px;
}

.user-profile-box .header h4 {
  font-size: 17px;
  color: #fff;
  font-weight: 300;
}

.user-profile-box .detail {
  padding-top: 100px;
}

.user-profile-box .profile-img {
  border-radius: 50%;
  background-clip: padding-box;
  border: 5px solid #fff;
  bottom: -75px;
  float: left;
  height: 160px;
  width: 160px;
  left: 50%;
  margin-left: -75px;
  position: absolute;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.user-profile-box .detail ul li .active {
  background: #fafafa;
  font-weight: 500;
}

.user-profile-box .detail ul li a:hover {
  background: #fafafa;
}

.user-profile-box .detail ul li a {
  border-bottom: 1px solid #f5f5f5;
  padding: 12px 20px;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.user-profile-box .detail ul li a i {
  margin-right: 10px;
  font-weight: 600;
}

.submit-property {
  overflow: hidden;
}
/** MY PROPERTIES  STARTED **/
.my-properties table {
  width: 100%;
}

.my-properties table thead tr th {
  background-color: #ececec;
  border: none;
  font-weight: 500;
  padding: 10px 10px 10px 0;
}

.my-properties table thead tr th:first-child {
  padding-left: 20px;
}

.my-properties table tbody tr td {
  border-top: none;
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 20px 20px 0;
  vertical-align: middle;
  font-size: 14px;
}

.my-properties table h2 {
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 7px 0;
  padding: 0;
}

.my-properties table tbody tr td.image {
  width: 120px;
}

.my-properties table tbody tr td.image img {
  border-radius: 3px;
}

.my-properties table tbody tr td.actions .edit {
  float: left;
}

.my-properties table tbody tr td .inner figure {
  margin-bottom: 5px;
}

.my-properties table tbody tr td .inner figure i {
  font-size: 13px;
}

.my-properties table tbody tr td .price {
  font-size: 12px;
  font-weight: 500;
}

.my-properties table tbody tr td.actions {
  text-align: right;
}

.my-properties table tbody tr td.actions .edit i {
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 5px;
}

.my-properties table tbody tr td.actions a {
  padding: 0;
}

.my-properties table tbody tr td:last-child {
  padding-right: 5px;
}

.submit-property .contact-2 .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  outline: 0;
  color: #f6f6f6;
  height: 45px;
  border-radius: 3px;
}

.submit-property .contact-2 textarea {
  min-height: 150px;
}

.submit-property .search-area .form-control:focus {
  color: #f6f6f6;
  background-color: #fff;
  border: 1px solid #e8e8e8 !important;
}

.notification-box {
  padding: 25px;
  color: #2e86b2;
  background-color: #bae8ff;
}

.notification-box p {
  margin-bottom: 0;
}

.property-gallery {
  position: relative;
  border: 2px dashed #d0d0d0;
  border-radius: 3px;
  background: #fcfcfc;
  transition: all 0.3s linear;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
  min-height: 150px;
  padding: 20px;
}

/** INTRO SECTION  STARTED **/
.intro-section {
  padding: 30px 0;
}

.intro-text h3 {
  font-size: 24px;
  margin: 0;
  line-height: 50px;
  color: #fff;
  font-weight: 300;
}

.intro-section .btn {
  background: #fff;
  float: right;
}

.intro-section .btn:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

/** SERVICES INFO STARTED **/
.services-info {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #f1f2f3;
}

.services-info:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.services-info i {
  font-size: 40px;
  width: 58px;
}

.services-info h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 8px 0 10px;
}

.services-info p {
  margin: 0;
}

/** SERVICES INFO 2 STARTED **/
.services-info-2 {
  text-align: center;
  max-width: 305px;
  margin: 0 auto 30px;
}

.services-info-2 h5 {
  font-size: 18px;
  line-height: 22px;
  margin: 15px 0 15px;
}

.services-info-2 i {
  font-size: 40px;
  margin-bottom: 30px;
}

.services-info-2 h3 {
  font-size: 15px;
  line-height: 22px;
  margin: 15px 0 20px;
}

.svs .services-info-2 {
  margin: 0 auto 50px;
}

/** SERVICES INFO 3 STARTED **/
.services-info-3 {
  text-align: center;
  padding: 20px;
  border: 1px solid #f1f2f3;
  border-right: none;
}

.services-info-3:last-child {
  border: 1px solid #f1f2f3;
}

.services-info-3:hover {
  -webkit-box-shadow: 0 1px 35px rgba(0, 0, 0, 0.07);
  box-shadow: 0 1px 35px rgba(0, 0, 0, 0.07);
  background: white;
}

.services-info-3 i {
  font-size: 45px;
  margin-bottom: 20px;
}

.services-info-3 h5 {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 10px 0;
}

.services-info-3 p {
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}

/** SERVICES INFO 5 STARTED **/
.services-info-5 {
  margin: 0 auto 30px;
  padding: 20px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  background: #fafafa;
}

.services-info-5 p {
  margin-bottom: 0;
}

.services-info-5 h5 {
  font-size: 18px;
  line-height: 22px;
  margin: 15px 0 15px;
}

.services-info-5 i {
  font-size: 40px;
  margin-bottom: 30px;
}

.services-info-5 h3 {
  font-size: 15px;
  line-height: 22px;
  margin: 15px 0 20px;
}

.svs .services-info-5 {
  margin: 0 auto 50px;
}

/** PARTNER AREA STARTED **/
.partner {
  text-align: center;
}

.partner .main-title {
  margin-bottom: 10px;
}

.partner .main-title h1 {
  font-size: 21px;
}

.multi-carousel {
  float: left;
  overflow: hidden;
  padding: 0 15px 15px;
  width: 100%;
  position: relative;
}

.multi-carousel .multi-carousel-inner {
  transition: 1s ease all;
  float: left;
}

.multi-carousel .multi-carousel-inner .item {
  float: left;
  cursor: pointer;
}

.multi-carousel .multi-carousel-inner .item > div {
  padding: 20px 15px 20px;
  margin: 10px;
  border: 1px solid #e6e7e8;
  color: #666;
  text-align: center;
}

.multi-carousel .multi-carousel-inner .item > div .carousel-img {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.multi-carousel .multi-carousel-inner .item > div .lead {
  font-size: 13px;
  font-weight: 500;
  color: #37404d;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.multi-carousel .multi-carousel-inner .item > div img {
  width: 130px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.multi-carousel .multi-carousel-inner .item:hover img {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.multi-carousel .leftLst,
.multi-carousel .rightLst {
  position: absolute;
  top: calc(50% - 20px);
}

.multi-carousel-indicator {
  width: 30px;
  height: 30px;
  line-height: 33px;
  z-index: 5;
  display: inline-block;
  right: 10px;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
  cursor: pointer;
}

.multi-carousel-indicator i {
  font-size: 20px;
  line-height: 22px;
  color: #333;
}

.multi-carousel .leftLst {
  left: 0;
}

.multi-carousel .rightLst {
  right: 0;
}

.multi-carousel .leftLst.over,
.multi-carousel .rightLst.over {
  pointer-events: none;
  background: #ccc;
  border: none;
}
/** PARTNER AREA ENDED **/

/** DROPZONE ENDED **/
.dropzone-design {
  border: 2px dashed #d0d0d0;
  min-height: 100px;
}

.dropzone-design,
.dropzone-design * {
  box-sizing: border-box;
}

.dropzone-design {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;
}

.dropzone-design.dz-clickable {
  cursor: pointer;
}

.dropzone-design.dz-clickable * {
  cursor: default;
}

.dropzone-design.dz-clickable .dz-message,
.dropzone-design.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone-design.dz-started .dz-message {
  display: none;
}

.dropzone-design.dz-drag-hover {
  border-style: solid;
}

.dropzone-design.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone-design .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone-design .dz-preview:hover {
  z-index: 1000;
}

.dropzone-design .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone-design .dz-preview.dz-file-preview .dz-image {
  border-radius: 3px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone-design .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone-design .dz-preview.dz-image-preview {
  background: white;
}

.dropzone-design .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone-design .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone-design .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone-design .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone-design .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone-design .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone-design .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone-design .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone-design .dz-preview .dz-details .dz-filename span,
.dropzone-design .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone-design .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone-design .dz-preview .dz-image {
  border-radius: 3px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  transition: all 0.3s;
  display: block;
  z-index: 10;
}

.dropzone-design .dz-preview .dz-image img {
  display: block;
  filter: blur(0px);
  transition: all 0.4s;
}

.dropzone-design .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview .dz-success-mark,
.dropzone-design .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone-design .dz-preview .dz-success-mark svg,
.dropzone-design .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone-design .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone-design .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone-design .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone-design .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone-design .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone-design .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone-design .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone-design .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone-design .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.dropzone-design {
  position: relative;
  border: 2px dashed #d0d0d0;
  border-radius: 3px;
  background: #fcfcfc;
  transition: all 0.3s linear;
  display: inline-block;
  width: 100%;
}

.dropzone-design:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.9)
  );
  background-color: #66676b;
  opacity: 0;
  z-index: 10;
  transition: all 0.3s linear;
  border-radius: 3px;
}

.dropzone-design:hover:before {
  opacity: 0.4;
}

.dropzone-design .dz-message {
  text-align: center;
  margin: 50px 0;
  font-size: 17px;
  color: #4a4747;
  transition: all 0.3s linear;
  position: relative;
  z-index: 11;
}

.dropzone-design:hover .dz-message {
  color: #66676b;
}

.dz-message i {
  display: block;
  font-size: 32px;
  margin-bottom: 15px;
}

.dropzone-design .dz-default.dz-message span {
  display: inline;
  line-height: 117px;
}

/** SERVICES INFO 4 5STARTED **/
.services-info-4 {
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
}

.services-info-4 h5 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.services-info-4 h5 a {
  text-transform: uppercase;
}

.services-info-4 .detail {
  padding: 30px 20px;
}

.services-info-4 p {
  margin-bottom: 20px;
}

.services-info-4 .btn {
  letter-spacing: 1px;
  font-size: 12px;
  padding: 6px 25px;
}

/** MODAL BODY DESIGN STARTED**/
.property-modal .modal-left {
  background: #3a3a3a;
  padding: 0px;
  color: #fff;
}

.property-modal .modal-content {
  border: none;
  background: transparent;
}

.property-modal .modal-header {
  display: initial;
  padding: 0px 0px 10px;
  border: none;
}

.property-modal .modal-header .modal-title {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.property-modal .modal-header p {
  color: #fff;
  margin-bottom: 0px;
}

.property-modal .modal-header .close,
.property-modal .modal-header .close:focus {
  padding: 7px 11px;
  outline: none;
  margin: -40px -3px -15px auto;
  background: #3a3a3a;
  opacity: 1;
  font-size: 17px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.property-modal .modal-body {
  padding: 0px 15px;
}

.modal-right {
  padding: 0px;
}

#modalCarousel .carousel-item {
  /*max-height: 240px;*/
}

.modal-left-content {
}

.modal-left-content p {
  font-size: 13px;
  color: #fff;
}

.modal-left-content img {
  width: 100%;
}

.modal-left-content .control {
  width: 30px;
  height: 30px;
  position: absolute;
  text-align: center;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  bottom: 0px;
  opacity: 0.3;
}

.modal-left-content .control:hover {
  opacity: 1;
}

.modal-left-content .control-prev {
  right: 30px;
}

.modal-left-content .control-next {
  right: 0px;
}

.modal-left-content .description {
  padding: 25px 25px 10px;
}

.modal-left-content .description h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;
}

.modal-right-content .bullets {
  font-size: 13px;
  font-weight: 500;
  margin: 20px 0;
  columns: 2;
}

.modal-right-content .bullets li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 25px;
}

.modal-right-content .bullets li i {
  left: 0;
  position: absolute;
  top: 0;
}

#modalCarousel .carousel-item iframe {
  display: block;
}

.modal-right-content {
  padding: 30px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-right-content .price {
  float: right;
}

.modal-right-content h3 {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #37404d;
}

.modal-right-content dl {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 12px;
}

.modal-right-content dl dt {
  float: left;
  font-weight: 500;
}

.modal-right-content dl dd {
  text-align: right;
  margin-bottom: 8px;
}

.modal-right-content dl dt .btn-show {
  padding: 7px 20px;
  letter-spacing: 2px;
}
/** MODAL BODY DESIGN ENDED **/

/** TESTIMONIAL STARTED **/
.testimonial {
  padding: 80px 0 70px;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial .testimonia-header {
  margin: 0 auto 40px;
}

.testimonial .testimonial-inner {
  max-width: 74%;
  margin: 0 auto;
}

.testimonial .testimonial-inner .carousel-inner {
  max-width: 600px;
  margin: 0 auto;
}

.testimonial .testimonial-inner .avatar {
  max-width: 120px;
  margin: 0 auto 20px;
}

.testimonial .testimonial-inner .author-name {
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.testimonial .testimonial-inner .lead {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 30px;
  color: #e6e6e6;
  font-weight: 400;
}

.testimonial .testimonial-inner .carousel-item {
  margin-bottom: 40px;
}

.testimonial .testimonial-inner .testimonia-header h1 {
  color: #e6e6e6;
  font-weight: 600;
  font-size: 34px;
}

.testimonial .carousel-indicators li {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
}

.testimonial .carousel-indicators {
  bottom: 0;
}

/** TESTIMONIAL 2 STARTED **/
.testimonial-2 {
  padding: 100px 0 130px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-2 .testimonial-inner .carousel-item {
  margin-bottom: 30px;
}

.testimonial-2 .testimonial-inner .lead {
  font-size: 17px;
  margin: 0 0 15px;
  line-height: 30px;
  color: #fff;
}

.testimonial-2 .testimonia-header {
  margin: 0 auto 50px;
  text-align: center;
}

.testimonial-2 .testimonial-inner .testimonia-header h1 {
  color: #fff;
  font-weight: 600;
  font-size: 34px;
}

.testimonial-2 .testimonial-inner .author-name {
  font-weight: 500;
  margin-bottom: 5px;
  color: #fff;
}

.testimonial-2 .testimonial-inner ul li {
  display: inline-block;
  color: orange;
}

.testimonial-2 .slider-mover-left {
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 100%;
  z-index: 5;
  display: inline-block;
  right: -30px;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
}

.testimonial-2 .slider-mover-left i {
  font-size: 20px;
  line-height: 30px;
  color: #000;
}

.testimonial-2 .slider-mover-right {
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 100%;
  z-index: 5;
  display: inline-block;
  right: 0;
  background: #a5a5a5;
  text-align: center;
  border-radius: 5%;
}

.testimonial-2 .slider-mover-right i {
  font-size: 20px;
  line-height: 30px;
  color: #000;
}

.testimonial-2 .carousel-control-next,
.carousel-control-prev {
  width: 0;
}

.testimonial-2 .testimonial-inner .rating li i {
  font-size: 13px;
}

/** TEAM STARTED 3**/
.testimonial-3 {
  background: #f1f1f1;
}

.testimonial-3 .testimonial-inner .testimonia-header h1 {
  color: #212121;
}

.testimonial-3 .testimonial-inner .lead {
  color: #535353;
}

.testimonial.testimonial-3 .lead {
  font-size: 18px;
}

.testimonial-3 .carousel-indicators li {
  width: 25px;
  height: 5px;
  background-color: #535353;
  border-radius: 10px;
}

.testimonial-3 .testimonial-inner .author-name {
  color: #212121;
}

.testimonial-3 .testimonial-inner .rating li {
  list-style: none;
  display: inline-block;
  color: orange;
  font-size: 11px;
}

.testimonial-3 .carousel-inner {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
}

/** AGENT 1 STARTED **/
.agent-1 {
  margin-bottom: 50px;
  text-align: center;
}

.agent-1 .agent-photo img {
  height: 180px;
}

.agent-1 .agent-details {
  padding: 25px 0 0 0;
}

.agent-1 .agent-details h5 a {
  font-size: 19px;
}

.agent-1 .agent-details p {
  margin-bottom: 15px;
}

.agent-1 .agent-details .social-list li {
  display: inline-block;
}

.agent-1 .agent-details .social-list li a {
  margin: 0 10px;
  color: #535353;
}

/** AGENT 2 STARTED **/
.agent-2 {
  margin-bottom: 30px;
  background: #fff;
}

.agent-2:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.agent-2 .agent-photo img {
  width: 100%;
}

.agent-2 .agent-details {
  text-align: center;
  padding: 20px;
}

.agent-2 .agent-details h5 {
  margin-bottom: 12px;
  font-weight: 500;
}

.agent-2 .agent-details h5 a {
  font-size: 18px;
}

.agent-2 .agent-details p {
  margin-bottom: 15px;
}

.agent-2 .agent-details .social-list li {
  display: inline-block;
}

.agent-2 .agent-details .social-list li a {
  margin: 0 10px;
  color: #535353;
}

/** AGENT 3 STARTED **/
.agent-3 {
  max-width: 320px;
  margin: 0 auto 30px;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.agent-3 .agent-photo img {
  position: relative;
}

.agent-3 .agent-details {
  padding: 25px 25px 20px;
  position: relative;
  float: left;
  width: 86%;
  margin: -80px 7% 30px 7%;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.agent-3 .agent-details p {
  padding-left: 25px;
  position: relative;
  font-size: 13px;
  margin-bottom: 0;
  line-height: 30px;
}

.agent-3 .agent-details p a {
  color: #535353;
}

.agent-3 .agent-details p i {
  position: absolute;
  left: 0;
  line-height: 30px;
}

.agent-3 .agent-details h5 {
  margin-bottom: 15px;
}

.agent-3 .agent-details h5 a {
  font-size: 18px;
}

.agent-3 .agent-details h6 {
  font-size: 12px;
  color: #535353;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 500;
}

/** AGENT 4 STARTED **/
.agent-4 {
  margin: 0 1px;
  background: #fff;
  margin-bottom: 30px;
}

.agent-4:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.agent-4 .photo {
  position: relative;
}

.agent-4 .photo img {
  width: 100%;
}

.agent-4 .photo .social-list {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 10px 10px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.agent-4 .photo .social-list ul li {
  list-style: none;
  display: inline-block;
}

.agent-4 .photo .social-list ul li a {
  margin: 0 10px;
  color: #fff;
}

.agent-4 .details h3 a {
  font-size: 20px;
}

.agent-4 .details h3 {
  font-weight: 500;
  margin: 0 0 10px;
}

.agent-4 .details h6 {
  font-weight: 400;
  margin: 0;
  font-size: 14px;
}

.agent-4 .details .contact ul li {
  line-height: 30px;
  font-size: 13px;
}

.agent-4 .details .contact ul li a {
  color: #535353;
}

.agent-4 .details .contact ul li span {
  font-weight: 500;
  margin-right: 5px;
}

.agent-4 .details {
  padding: 0 20px 0 30px;
}

/** AGENT 5 STARTED **/
.agent-5 {
  margin: 0 0 30px;
}

.agent-5 img {
  width: 160px;
  height: 160px;
  border-radius: 3px;
  margin-right: 20px;
}

.agent-5 .media-body {
  padding-right: 30px;
}

.agent-5 h3 {
  font-size: 21px;
  margin-bottom: 10px;
}

.agent-5 h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.agent-5 p {
  font-size: 14px;
  margin: 0;
}

/** AGENT PAGE STARTED **/
.agent-page .heading {
  margin-bottom: 25px;
  font-size: 19px;
  text-transform: uppercase;
}

.additional-details-list li {
  list-style: none;
  line-height: 36px;
  font-weight: 500;
  font-size: 15px;
}

.additional-details-list li span {
  margin-right: 10px;
  float: left;
  font-weight: 500;
}

.attachments a i {
  margin-right: 10px;
}

.agent-6 {
  margin-bottom: 60px;
}

.agent-6 .details h3 {
  font-weight: 500;
  margin: 10px 0 20px;
}

.agent-6 .details h3 a {
  font-size: 30px;
}

/** PRICING TABLES STARTED **/
.pricing {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.pricing .price-header {
  width: 100%;
  background: #fff;
  padding: 35px 20px;
}

.pricing .price-header .title {
  margin: 0 0 10px;
  font-size: 20px;
  color: #212121;
}

.pricing .price-header .price {
  font-size: 35px;
  font-weight: 300;
  margin: 0;
  color: #212121;
}

.pricing .content {
  padding: 20px 20px 30px;
  background: #f9f9f9;
}

.pricing .content ul {
  margin: 0 0 20px;
}

.pricing .content ul li {
  padding: 8px 0;
  font-size: 14px;
  color: #535353;
}

.pricing .btn {
  border-radius: 3px;
  padding: 6px 30px;
}

.pricing.featured .listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.pricing.featured .listing-badges .featured {
  float: left;
  transform: rotate(-45deg);
  left: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 12.5px;
  margin: 0;
  padding: 7px 10px;
  font-weight: 500;
  color: #fff;
}

.pricing-tables .mrg-btn-76 {
  margin-bottom: 76px;
}

/** PRICING TABLES 3 STARTED **/
.pricing-3 {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 15px;
}

.pricing-3:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.pricing-3.popular {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.pricing-3 .title {
  font-size: 18px;
  margin: 0 0 20px;
  text-transform: uppercase;
  color: #212121;
}

.pricing-3 .content {
  padding: 25px 0;
}

.pricing-3 .content ul li {
  padding: 7px 0;
  font-size: 14px;
  color: #535353;
}

.pricing-3 .price {
  font-size: 45px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #212121;
}

.pricing-3 .manth {
  font-weight: 500;
  font-size: 13px;
  color: #212121;
}

.pricing-3 .pricing-btn {
  border-radius: 3px;
  padding: 6px 30px;
}

/** CONTACT 1 STARTED **/
.contact-1 .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #e8e8e8;
  background: #e8e8e8;
  outline: 0;
  color: #f6f6f6;
  height: 45px;
  border-radius: 3px;
}

.contact-1 .form-control:focus {
  color: #495057;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  outline: 0;
  box-shadow: none;
}

.contact-1 .form-group {
  margin-bottom: 30px;
}

.contact-1 textarea {
  min-height: 200px;
}

.contact-1 .contact-info .media {
  margin-bottom: 30px;
}

.contact-1 .contact-info .media i {
  width: 55px;
  font-size: 20px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  border: 1px dashed #535353;
  text-align: center;
  margin-right: 25px;
}

.contact-1 .contact-info h3 {
  margin-bottom: 35px;
  font-weight: 500;
}

.contact-1 .contact-info .media h5 {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.contact-1 .contact-info .media p {
  margin-bottom: 5px;
  font-size: 14px;
}

.contact-1 .contact-info .media p a {
  color: #535353;
}

.contact-1 .contact-info .media.mrg-btn-0 {
  margin-bottom: 0;
}

.contact-1 h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

/** CONTACT 2 STARTED **/
.contact-2 .contact-info {
  text-align: center;
}

.contact-2 h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.contact-2 .contact-info strong {
  font-weight: 500;
  font-size: 14px;
}

.contact-2 .contact-info i {
  font-size: 24px;
  margin-bottom: 20px;
  color: #212529;
}

.contact-2 .contact-info p {
  margin-bottom: 5px;
}

.contact-2 .contact-info .contact-info {
  margin: 10px 0 50px;
}

.contact-2 .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #dedede;
  background: transparent;
  outline: 0;
  height: 45px;
  border-radius: 3px;
}

.contact-2 .form-control:focus {
  color: #495057;
  background-color: transparent;
  border: 1px solid #dedede;
  outline: 0;
  box-shadow: none;
}

.contact-2 .form-group {
  margin-bottom: 20px;
}

.contact-2 textarea {
  min-height: 240px;
}

/** CONTACT 3 STARTED **/
.contact-3 .contact-info h3 {
  margin-bottom: 30px;
  font-weight: 300;
}

.contact-3 .contact-info .contact-list {
  margin: 0 0 60px;
}

.contact-3 .contact-info .contact-list li {
  list-style: none;
  padding-left: 40px;
  font-size: 14px;
  position: relative;
  margin-bottom: 20px;
  color: #535353;
  line-height: 22px;
}

.contact-3 .contact-info .contact-list li i {
  left: 0;
  position: absolute;
  top: 0;
  line-height: 22px;
  font-size: 18px;
}

.contact-3 .contact-info .social-list {
  margin: 0 0 30px;
}

.contact-3 .contact-info .social-list li a {
  font-size: 16px;
  float: left;
  margin-right: 20px;
  color: #535353;
}

.contact-3 .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #dedede;
  background: transparent;
  outline: 0;
  color: #f6f6f6;
  height: 45px;
  border-radius: 3px;
}

.contact-3 .form-control:focus {
  color: #495057;
  background-color: transparent;
  border: 1px solid #eee;
  outline: 0;
  box-shadow: none;
}

.contact-3 .form-group {
  margin-bottom: 20px;
  width: 100%;
}

.contact-3 textarea {
  min-height: 190px;
}

/** PAGES 404 **/
.pages-404 .pages-404-inner {
  text-align: center;
}

.pages-404 .pages-404-inner h1 {
  color: #eee;
  font-size: 250px;
  font-weight: 300;
  letter-spacing: 20px;
}

.pages-404 .pages-404-inner h5 {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 300;
}

.pages-404 .pages-404-inner .btn {
  padding: 6px 30px;
  font-size: 12px;
}

.pages-404 .pages-404-inner .e404 {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
  top: 50%;
  left: 50%;
}

/** FOOTER STARTED **/
.footer h4 {
  font-size: 19px;
  margin-bottom: 30px;
  color: #c5c5c5;
  font-weight: 400;
  letter-spacing: 1px;
}

.footer a {
  color: #7c7e83;
  font-size: 13px;
}

.footer a:hover {
  color: #717171;
}

.footer {
  background: #111;
  padding: 100px 0 0;
}

.footer .footer-inner p {
  line-height: 27px;
  color: #7c7e83;
  font-size: 13px;
}

.footer .footer-inner .links li {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  line-height: 20px;
}

.footer .footer-inner .links li a i {
  left: 0;
  position: absolute;
  top: 0;
  line-height: 22px;
}

.footer .footer-inner .contact-info li {
  margin-bottom: 15px;
  color: #7c7e83;
  font-size: 13px;
}

.footer .footer-inner .social-list {
  margin: 30px 0 0;
}

.footer .footer-inner .social-list li a {
  font-size: 14px;
  float: left;
  margin-right: 20px;
}

.footer .copy {
  padding: 20px 0;
  border-top: solid 1px #1b1b1b;
  margin: 50px 0 0;
  text-align: center;
}

.footer .footer-inner .gallery li {
  float: left;
}

.footer .footer-inner .gallery li img {
  margin: 0 10px 10px 0;
  width: 70px;
  border-radius: 1px;
}

.footer .footer-inner .Subscribe-box {
  padding: 20px 20px 10px;
  background: #151515;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.footer .footer-inner .Subscribe-box .btn-color {
  border: none;
}

.footer .footer-inner .Subscribe-box form .form-contact {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  border: 1px solid #ebebeb;
  font-size: 13px;
  outline: none;
  border-radius: 2px;
}

.footer .footer-inner .Subscribe-box form .btn {
  padding: 11px 15px 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 2px;
}

.footer-item {
  margin-bottom: 50px;
}

/** BLOG CLASSIC STARTED **/
.blog-1 {
  border: none;
  margin-bottom: 30px;
  position: relative;
  background: #fff;
}

.blog-1:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.blog-1 img {
  border-radius: 0;
  width: 100%;
}

.blog-1 .detail h1 {
  margin-bottom: 10px;
}

.blog-1 .detail h2 a {
  font-size: 23px;
}

.blog-1 .detail h3 {
  margin-bottom: 10px;
}

.blog-1 .detail h3 a {
  font-size: 19px;
}

.blog-1 .detail {
  padding: 20px 20px 10px;
}

.blog-1 .detail .post-meta {
  margin: 15px 0;
  font-size: 14px;
}

.blog-1 .detail .post-meta span a i {
  margin-right: 5px;
}

.blog-1 .detail .post-meta span a {
  color: #535353;
}

.blog-1 .detail .post-meta span {
  margin-right: 20px;
}

.blog-1 .detail .post-meta span:last-child {
  margin: 0;
}

.blog-1 .date-box {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  letter-spacing: 1px;
  padding: 12px;
  text-align: center;
}

.blog-1 .date-box h5 {
  color: #fff;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
}

.blog-1 .btn-read-more {
  font-weight: 500;
  font-size: 11px;
  background: #e5e5e5;
  padding: 7px 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #5f5f5f;
}

.blog-1 .btn-read-more:hover {
  background: #d5d5d5;
  color: #444;
}

/** BLOG 2 STARTED **/
.blog-2 {
  position: relative;
}

.blog-2 .detail {
  position: relative;
  float: left;
  width: 90%;
  margin: -30px 5% 30px 5%;
  padding: 25px 25px 15px 25px;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.blog-2 .detail h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.blog-2 .detail h3 a:hover {
  color: #535353;
}

.blog-2 .post-meta {
  width: 100%;
  margin-bottom: 10px;
}

.blog-2 .detail .post-meta span {
  margin-right: 20px;
  font-size: 13px;
}

.blog-2 .detail p {
  font-size: 14px;
  margin-bottom: 0;
}

.blog-2 .date-box {
  position: absolute;
  top: 15px;
  font-size: 13px;
  color: #fff;
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 13px;
  right: 19px;
}

.blog-2 .date-box::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-bottom: 20px solid transparent;
  bottom: -20px;
  right: 0;
}

.blog-2 .date-box span {
  font-family: montserrat, sans-serif;
  font-size: 19px;
  display: block;
  line-height: 22px;
  color: #fff;
}

.blog-2 .detail .post-meta span a i {
  margin-right: 5px;
}

/** BLOG 3 STARTED **/
.blog-3 {
  background: #fff;
  margin: 0 1px;
  margin-bottom: 30px;
}

.blog-3:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.blog-3 .date-box {
  position: absolute;
  font-size: 12px;
  color: #fff;
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 13px;
  left: 15px;
  line-height: normal;
  top: 15px;
}

.blog-3 .date-box span {
  font-size: 16px;
  display: block;
}

.blog-3 .date-box::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-bottom: 20px solid transparent;
  bottom: -20px;
  left: 0;
}

.blog-3 .detail {
  padding: 0 20px;
}

.blog-3 .detail h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

.blog-3 .detail h3 a {
  font-size: 20px;
}

.blog-3 .detail .post-meta {
  margin: 15px 0;
  font-size: 13px;
}

.blog-3 .detail .post-meta span {
  margin-right: 20px;
}

.blog-3 .detail .post-meta span:last-child {
  margin: 0;
}

.blog-3 .detail p {
  margin-bottom: 0;
}

.blog-3 .detail .post-meta span a i {
  margin-right: 10px;
}

/** BLOG 4 STARTED **/
.blog-4 {
  margin: 0 1px;
  margin-bottom: 30px;
}

.blog-4 .bg {
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.blog-4 .detail {
  padding: 30px 0 30px 25px;
  background: #fff;
  left: -30px;
  position: relative;
}

.blog-4 .detail h4 {
  margin-bottom: 15px;
  font-size: 20px;
}

.blog-4 .detail p {
  margin-bottom: 0;
}

.blog-4 .detail .post-meta {
  margin: 15px 0;
  margin-bottom: 10px;
  font-size: 13px;
}

.blog-4 .detail .post-meta span {
  margin-right: 20px;
}

.blog-4 .detail .post-meta span:last-child {
  margin: 0;
}

.blog-4 .detail .post-meta span a i {
  margin-right: 10px;
}

/** PAGINATION STARTED **/
.pagination-box {
  margin-bottom: 25px;
}

.pagination {
  padding-left: 0;
  border-radius: 4px;
  display: inline-block;
}

.pagination li {
  display: inline-block;
}

.pagination > li > a,
.pagination > li > span {
  border-radius: 0;
  margin: 0 5px 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
  line-height: 40px;
  border: none;
}

.pagination > li > a {
  color: #212529;
  font-size: 20px;
  background: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.pagination > li > .active {
  color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.pagination > li > a:hover {
  color: #fff;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/** FORM SEARCH STARTED **/
.form-search {
  position: relative;
}

.form-search .form-control {
  border: 1px solid #eee;
  height: 45px;
}

.form-search .btn {
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 12px 9px 12px;
}

.show-more-options {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}

.widget {
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
}

.sidebar-title {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
}

.sidebar {
  background: #fff;
}

/** Campare properties **/
.compare-properties {
  margin-bottom: 30px;
  border-radius: 0;
  border: none;
}

#compare-search {
  margin-bottom: 15px;
}

#compare-search .form-group {
  margin-bottom: 15px;
}

.compare-properties .card-img-top {
  border-radius: 0;
}

.compare-properties h5 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 500;
}

.compare-properties h6 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.compare-properties p {
  margin: 0;
}

.compare-properties p i {
  color: #ffc12b;
}

.comparison .heading h3 {
  font-size: 20px;
  margin: 0;
}

.comparison .heading {
  margin-bottom: 30px;
}

.comparison .faq-accordion .card-block {
  padding: 0;
}

.comparison .faq-accordion .card-title {
  font-size: 18px;
}

/** COMPARE TABLE **/
.compare-table tr {
  border: 1px solid #ececec;
}

.compare-table tr:first-child {
  border-top: none;
}

.compare-table tr td:first-child {
  width: 350px;
  background: #f8f9fa;
}

.compare-table tr td {
  box-sizing: border-box;
  font-size: 15px;
  padding: 20px 15px;
  text-align: center;
  width: 395px;
  font-weight: 500;
}

.compare-table tr td .fa-times {
  color: red;
}

.compare-table tr td .fa-check {
  color: green;
}

/** CATEGORIES STARTED **/
.categories ul li {
  line-height: 30px;
  font-size: 14px;
}

.categories ul li a {
  color: #535353;
}

.categories ul li span {
  float: right;
}

/** TAGS STARTED **/
.tags ul li a {
  float: left;
  margin: 0 5px 5px 0;
  padding: 6px 12px 4px;
  font-size: 11px;
  text-decoration: none;
  border: solid 1px #e4e4e4;
  background: #e4e4e4;
  text-transform: uppercase;
  color: #5f5f5f;
  letter-spacing: 1px;
  border-radius: 2px;
}

.tags ul li a:hover {
  color: #fff;
}

/** RECENT POSTS STARTED **/
.recent-posts .media h5 {
  margin-bottom: 7px;
  font-weight: 500;
}

.recent-posts .media h5 a {
  font-size: 17px;
}

.recent-posts .media img {
  width: 75px;
  border-radius: 2px;
  margin-right: 20px;
}

.recent-posts .media p {
  margin: 0;
  line-height: 24px;
  font-size: 13px;
}

.recent-posts .media p strong {
  font-weight: 500;
}

/** RECENT COMMENTS STARTED **/
.recent-comments img {
  width: 50px;
}

.recent-comments .media p {
  line-height: 23px;
  margin-bottom: 10px;
  font-size: 14px;
}

.recent-comments .media p span {
  font-weight: 500;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  background: #f9f6f6;
  border-left: 5px solid #212529;
}

blockquote p {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
}

blockquote footer {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
}

.tags-socal-box .social-list {
  float: right;
  text-align: right;
}

.tags-socal-box h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.tags-socal-box .social-list ul li {
  display: inline-block;
  margin-left: 20px;
}

.tags-socal-box .social-list ul li a {
  color: #535353;
}

.tags-socal-box .social-list ul li:first-child {
  margin: 0;
}

.tags-socal-box {
  margin-bottom: 10px;
}

/** COMMENT STARTED **/
.comment {
  margin: 0 0 60px 0;
  position: relative;
}

.comments-section h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.comment .comment-author {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.comments ul {
  list-style: none;
  margin: 0 0 0 80px;
  padding: 0;
}

.comment .comment-author a img {
  bottom: 2px;
  border-radius: 50%;
  display: block;
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
}

.comment .comment-content {
  margin-left: 100px;
}

.comment .comment-meta-author {
  float: left;
  font-weight: 500;
  font-size: 14px;
}

.comment .comment-meta-reply {
  float: right;
  margin-left: 15px;
  font-size: 13px;
}

.comment .comment-meta-date {
  float: right;
  font-size: 13px;
}

.comment .comment-body {
  margin-top: 10px;
}

.comment .comment-rating {
  color: orange;
  margin-bottom: 5px;
  font-size: 12px;
}

/** SIMPLE CONTENT BOX **/
.simple-content {
  padding-top: 100px;
  color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.simple-content p {
  color: #eaeaea;
  font-weight: 300;
  line-height: 32px;
  font-size: 17px;
  margin-bottom: 20px;
}

.simple-content h1 {
  color: #eaeaea;
  margin-bottom: 30px;
  font-size: 30px;
}

/** SHOP BOX **/
.shop-box {
  text-align: center;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.shop-box img {
  width: 100%;
}

.shop-box .shop-details .btn {
  padding: 5px 20px;
  font-size: 11px;
  letter-spacing: 1px;
}

.shop-box .shop-details {
  padding: 30px 15px;
}

.shop-box .shop-details h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.shop-box .shop-details h3 a {
  font-size: 15px;
}

.shop-box .shop-details h3 {
  font-weight: 500;
  margin-bottom: 15px;
}

/** SHOP SLIDER **/
.shop-slider .carousel-control-next,
.carousel-control-prev {
  width: 0;
}

/** SHOP SINGLE **/
.shop-single .shop-info h3 {
  font-weight: 500;
  margin-bottom: 25px;
  font-size: 24px;
}

.shop-single .shop-info p {
  line-height: 28px;
  font-size: 16px;
  padding: 10px 0;
}

.shop-single .shop-info .reviews-box {
  padding: 10px 0;
  border-top: solid 1px #e9e9e9;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: 20px;
}

.shop-single .shop-info .reviews-box ul li {
  float: left;
  font-size: 12px;
  line-height: 25px;
}

.shop-single .shop-info .reviews-box .reviews {
  float: left;
  font-size: 11px;
  text-transform: uppercase;
  margin-left: 10px;
  line-height: 25px;
}

.shop-single .shop-info .reviews-box .reviews a {
  margin-right: 10px;
  padding-right: 10px;
  border-right: solid 1px #777;
  color: #535353;
}

.shop-single .shop-info .reviews-box .reviews a:last-child {
  border-right: solid 1px transparent;
  padding: 0;
  margin: 0;
}

.shop-single .selectBox-dropdown .selectBox-arrow {
  border-left: none;
}

.shop-single .shop-info .reviews-box .reviews a i {
  margin-right: 10px;
}

.shop-single .widget.shop-info {
  margin-bottom: 15px;
}

.shop-single .shop-info .reviews-box ul li i {
  margin-right: 3px;
  color: orange;
}

.shop-single .shop-info .price {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.shop-single .shop-info .price del {
  margin-right: 15px;
  color: #000;
}

.shop-single .horizontal ul li {
  line-height: 30px;
  width: 100%;
  font-size: 14px;
}

.shop-single .horizontal ul li span {
  width: 40%;
  float: left;
}

.shop-single .shop-info .form-control {
  width: 100% !important;
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #eee;
  background: transparent;
  outline: 0;
  color: #777;
  height: 45px;
  border-radius: 3px;
}

.shop-single .shop-info .form-group {
  margin-bottom: 25px;
}

.shop-single .shop-info .btn-md {
  padding: 11px 20px;
}

/** SHOP CART **/
table {
  width: 100%;
}

.shop-header {
  margin-bottom: 30px;
}

.shop-table.cart .product-thumbnail,
.shop-table.cart .product-name {
  padding-left: 0;
}

.shop-table.cart .product-thumbnail,
.shop-table.cart .product-name a {
  text-transform: none;
  font-size: 14px;
}

.shop-table th {
  padding: 15px 0;
  border: none;
}

.shop-table.cart td,
.shop-table.cart th {
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 13px;
}

.shop-table.cart .product-remove {
  padding-right: 0;
}

.shop-table td {
  border-top: 1px solid #ddd;
  padding: 40px 0;
}

.shop-table.cart .product-thumbnail img {
  width: 100px;
}

.shop-table.cart .qty {
  width: 60px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.shop-table input[type="text"],
input[type="email"],
textarea,
input[type="password"],
input[type="tel"] {
  border: 1px solid #e8e8e8;
  padding: 6px 15px;
  color: #777;
  max-width: 100%;
  outline: none;
}

.cart-total-box {
  padding: 25px;
}

.cart-total-box h5 {
  font-weight: 500;
  font-size: 18px;
}

.cart-total-box ul li {
  line-height: 33px;
  width: 100%;
  font-size: 14px;
}

.cart-total-box ul li span {
  margin-right: 0;
}

.cart-total-box .btn {
  padding: 12px 20px 10px;
  font-size: 12px;
}

/** SHOP CHECKOUT **/
.shop-checkout .form-group {
  margin-bottom: 30px;
}

.shop-checkout .form-control {
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #eee;
  background: transparent;
  outline: 0;
  color: #535353;
  height: 45px;
  border-radius: 3px;
}

/** SHOP DETAILS **/
.shop-single .shop-title {
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 22px;
}

.shop-details-content p {
  font-size: 14px;
  line-height: 28px;
}

.payment-box {
  margin-bottom: 30px;
}

.payment-box p {
  padding: 10px 0 0 20px;
}

/** Content form box STARTED **/
.content-box {
  background: #fff;
}

.content-form-box .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #efefef;
  outline: 0;
  color: #6c6c6c;
  height: 42px;
  border-radius: 3px;
}

.content-form-box .form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 1px solid #efefef;
  outline: 0;
  box-shadow: none;
}

.content-form-box .form-group {
  margin-bottom: 25px;
}

.content-form-box .btn {
  cursor: pointer;
  padding: 11px 20px 10px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  border-radius: 3px;
}

.login-header {
  font-weight: 500;
  margin: 0 0 20px;
  font-size: 22px;
  color: #515365;
}

.login-header h4 {
  font-size: 22px;
  color: #515365;
}

.login-header img {
  height: 30px;
}

.form-check {
  font-size: 13px;
}

.content-form-box {
  padding: 0 70px;
  left: 0;
  height: 100vh;
  display: table-cell;
  vertical-align: middle;
}

.content-form-box.forgot-box {
  display: inherit;
}

.content-form-box p {
  font-size: 13px;
  color: #80828e;
}

.form-check-label {
  font-size: 13px;
  color: #80828e;
}

.login-footer img {
  height: 20px;
}

.login-footer {
  padding: 20px;
  border-top: solid 1px #ececec;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.content-form-box.register-box .login-footer {
  width: 100%;
  position: inherit;
}

.content-form-box.forgot-box {
  width: 100%;
  position: inherit;
  bottom: 0;
}

.login-footer p {
  font-size: 13px;
  margin: 0;
}

.login-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  max-width: 600px;
}

.login-info h3 {
  color: #dad9d9;
}

.login-info p {
  color: #dad9d9;
  font-size: 17px;
  line-height: 30px;
  font-weight: 300;
}

.register-box {
  max-width: 450px;
}

.register-info {
  position: absolute;
  top: 30%;
  left: 0;
  padding: 30px 80px;
  text-align: right;
}

.register-info img {
  height: 50px;
  margin-bottom: 20px;
}

.register-info p {
  color: #dad9d9;
  font-size: 19px;
  line-height: 30px;
  font-weight: 300;
}

.forgot-box {
  max-width: 420px;
  border-radius: 8px;
  background: #fff;
  padding: 50px;
}

/** FAQ **/
.faq-box {
  border: 1px solid transparent;
  border-radius: 0;
  margin-bottom: 0;
}

.faq-box h5 .btn-link {
  padding: 25px 20px;
  width: 100%;
  text-align: left;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #535353;
}

.faq-box .card-header {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 14px;

  border-bottom: solid 1px #d8d8d8;
}

.bd-none {
  border-bottom: none;
}

.faq-box .card-body p {
  line-height: 27px;
}

.faq-box .card-body span {
  font-size: 14px;
}

.faq-box .card-body span a {
  font-weight: 600;
}

.faq-accordion {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.faq-accordion .card {
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #f1f2f3;
  background-color: #fff;
}

.faq-accordion .card-header {
  padding: 25px 20px 20px 20px;
  background: #fff;
}

.faq-accordion .card-title {
  margin-bottom: 0;
  padding-right: 20px;
  letter-spacing: 0;
  font-size: 16px;
  cursor: pointer;
}

.faq-accordion .card-block {
  padding: 25px 20px 20px 20px;
}

.faq-accordion .card-header a:before {
  font-family: "FontAwesome";
  content: "\f077";
  font-size: 13px;
  font-weight: 300;
  margin-right: 20px;
}

.faq-accordion .card-header a.collapsed:before {
  content: "\f078";
  font-size: 13px;
  font-weight: 300;
  margin-right: 20px;
}

.faq-accordion .answer-helpful {
  font-size: 12px;
}

.faq-accordion .answer-helpful .yes {
  margin-left: 5px;
  color: #155724;
  font-size: 14px;
}

.faq-accordion .answer-helpful .no {
  margin-left: 5px;
  color: #721c24;
  font-size: 14px;
}

/** TYPOGRAPHY **/
.typography .mb-60 {
  margin-bottom: 40px;
}

.typography .heading {
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.typography p {
  font-size: 14px;
  margin: 0;
}

/** typography 2 start **/
.typography-2 .heading {
  text-transform: uppercase;
  margin: 20px 0;
}

.typography-2 .sd p {
  margin-bottom: 40px;
}

.hr-title.hr-long {
  width: 50%;
  margin: 7px auto 30px;
}

.hr-title {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  margin: 10px 0;
  height: auto;
  color: #bbb;
}

.hr-title abbr {
  background-color: #fafafa;
  padding: 2px 10px;
  border-radius: 2px;
  position: relative;
  top: -10px;
  letter-spacing: 0.2em;
}

.typography-2 address {
  margin-bottom: 20px;
  font-style: normal;
  font-size: 14px;
  line-height: 26px;
  color: #535353;
}

.typography-2 .space {
  width: 100%;
  display: block;
  clear: both;
  height: 20px;
  position: relative;
  border-top: none;
}

/** PARTNERS BLOCK**/
.slick-slide img {
  width: 80%;
}

.slick-active img {
  /*filter: grayscale(100%);*/
}

.slick-current img {
  width: 100%;
  filter: grayscale(0%);
}

.slick-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
  color: #777;
  cursor: pointer;
  outline: none;
}

.slick-prev {
  border: none;
  position: absolute;
  left: 0px;
  top: 20px;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next {
  background: none;
  border: none;
  position: absolute;
  right: 0px;
  top: 20px;
}

.slick-next:before {
  content: "\f054";
}

/** COUNTERS **/
.counters {
  padding-top: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.counters.overview-bgi:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.counter-box {
  text-align: center;
  margin-bottom: 50px;
}

.counter-box i {
  font-size: 40px;
  color: #fff;
}

.counter-box h1 {
  color: #fff;
  font-weight: 500;
  margin: 20px 0;
}

.counter-box h5 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

/** ABOUT **/
.about-text ul li {
  list-style: none;
  color: #5a5959;
  font-size: 14px;
}

.about-text h3 {
  margin-bottom: 20px;
  font-size: 23px;
}

.properties-service-v img {
  width: 100%;
}

.about-text p b {
  font-weight: 500;
}

.about-text p {
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
}

.about-text ul li {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.about-text ul li i {
  left: 0;
  position: absolute;
  top: 0;
  line-height: 22px;
}

.about-us iframe,
.modal-body iframe .element.style {
  height: 350px !important;
}

.about-us iframe,
.modal-body iframe {
  width: 100%;
  border: none !important;
}

.properties-details-page .heading {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
}

.properties-details-page iframe,
.modal-body iframe .element.style {
  height: 350px;
}

.properties-details-page iframe,
.modal-body iframe {
  width: 100%;
  border: none !important;
}

.properties-details-sliders .list-inline {
  white-space: nowrap;
  width: 100% !important;
}

.properties-details-sliders .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-left: initial;
  margin-top: 0;
}

.properties-details-sliders .carousel-indicators a {
  cursor: pointer;
}

.properties-details-sliders .carousel-indicators > li {
  height: initial;
  text-indent: initial;
  margin-right: 0;
  margin-left: 0;
}

.dd.properties-details-sliders .carousel-indicators > li {
  width: initial;
  height: initial;
  text-indent: initial;
}

.smail-properties .list-inline-item {
  width: 20%;
}

.smail-properties .list-inline-item a img {
  width: 100%;
  border: none;
  border-radius: 0;
}

.properties-details-sliders .carousel-indicators > li.active img {
  opacity: 0.7;
}

.properties-details-sliders .right {
  position: absolute;
  right: 15px;
  top: 45%;
}

.properties-details-sliders .left {
  left: 10px;
  top: 47%;
  width: 30px;
  height: 30px;
  line-height: 33px;
  position: absolute;
  z-index: 5;
  display: inline-block;
  right: -30px;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
}

.properties-details-sliders .left i {
  font-size: 20px;
  color: #000;
}

.properties-details-sliders .right {
  right: 10px;
  top: 47%;
  width: 30px;
  height: 30px;
  line-height: 33px;
  position: absolute;
  z-index: 5;
  display: inline-block;
  text-align: center;
  background: #a5a5a5;
  border-radius: 5%;
}

.properties-details-sliders .right i {
  font-size: 20px;
  color: #000;
}

.list-inline-item:not(:last-child) {
  margin-right: 1px;
}

.smail-properties {
  width: 100%;
}

/** FLOOR PLANS STARTED **/
.floor-plans table {
  width: 100%;
  margin-bottom: 15px;
}

.floor-plans table {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.floor-plans table tr:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.floor-plans table td {
  font-size: 13px;
}

.floor-plans table td,
table th {
  padding: 10px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}

/** TABBING BOX STARTED **/
.tabbing-box .tab-pane {
  padding-top: 50px;
}

.tabbing-box .nav-tabs {
  background: #fff;
  border-bottom: solid 1px #fff;
}

.tabbing-box .nav-tabs li {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  padding: 12px 18px;
  color: #37404d;
  border: none;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #858d98;
  border-radius: 0;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border-radius: 0;
  color: #858d98;
}

/** FINANCING CALCULATOR STARTED **/
.financing-calculator .form-label {
  margin-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
}

.financing-calculator .form-group {
  margin-bottom: 20px;
}

.financing-calculator .form-control {
  width: 100%;
  padding: 10px 20px;
  font-size: 13px;
  border: 1px solid #e8e8e8;
  background: #fff;
  outline: 0;
  color: #f6f6f6;
  height: 45px;
  border-radius: 3px;
}

.financing-calculator .form-control:focus {
  border: 1px solid #e8e8e8;
  background: #fff;
}

.location #map {
  width: 100%;
  height: 350px;
}

.heading-properties {
  margin-bottom: 15px;
}

.heading-properties h5 {
  font-size: 15px;
}

.heading-properties h3 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 25px;
}

.heading-properties p {
  margin-bottom: 0;
}

.p-r {
  float: right;
  text-align: right;
}

.p-r i {
  color: #ffc12b;
}

.property-details ul li {
  font-weight: 400;
  line-height: 35px;
  font-size: 14px;
}

.property-details ul li strong {
  margin-right: 5px;
  font-weight: 500;
}

/** AMENITIES BOX STARTED **/
.amenities-box li {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
  color: #5a5959;
  font-size: 14px;
}

.amenities-box li i {
  left: 0;
  position: absolute;
  top: 0;
  font-weight: 500;
}

.features-opions ul li {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
  color: #5a5959;
  font-size: 14px;
}

.features-opions ul li i {
  left: 0;
  position: absolute;
  top: 0;
  font-weight: 500;
}

.af ul li i {
  font-size: 10px;
  line-height: 21px;
}

/** SELL YOUR PROPERTIES **/
.sell-your-properties {
  text-align: center;
  background: rgba(0, 0, 0, 0.04) url(../../assets/img/bg-photo-2.jpg) top left
    repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 30px 20px;
  margin: 40px 0;
}

.sell-your-properties h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sell-your-properties p {
  color: #fff;
}

/** HELPING CENTER **/
.helping-center {
  background: rgba(0, 0, 0, 0.04) url(../../assets/img/bg-photo-2.jpg) top left
    repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 20px;
  margin: 40px 0;
  width: 100%;
}

.helping-center i {
  font-size: 75px;
  margin-right: 15px;
}

.helping-center h5 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.helping-center h4 {
  margin: 0;
}

.helping-center h4 a {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.sidebar .social-list li {
  text-align: center;
  display: inline-block;
  list-style: none;
}

.sidebar .social-list li a {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin: 2px 4px 2px 0;
  border-radius: 0%;
}

/** COMING SOON **/
.coming-soon {
  background: rgba(0, 0, 0, 0.04) url(../../assets/img/bg-photo-2.jpg) top left
    repeat;
  background-size: cover;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  min-height: 100vh;
}

.coming-soon-inner .cm {
  position: absolute;
  top: 30%;
  left: 8%;
  width: 84%;
  right: 8%;
}

.coming-soon-inner .cmb {
  bottom: 30px;
  position: absolute;
  left: 8%;
  width: 84%;
  right: 8%;
}

.coming-soon-inner .cmt {
  top: 20px;
  position: absolute;
  left: 8%;
  width: 84%;
  right: 8%;
}

.coming-soon-inner {
  color: #fff;
}

.coming-soon-inner h1 {
  font-weight: 700;
  font-size: 100px;
  margin-bottom: 30px;
  color: #fff;
}

.coming-soon-inner ul li {
  display: inline-block;
  font-size: 16px;
  margin-left: 15px;
  float: right;
}

.coming-soon-inner ul li a {
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  border-radius: 5px;
  text-align: center;
}

.coming-soon-inner .coming-form {
  max-width: 70%;
}

.coming-soon-inner .bordered-right {
  border-right: 1px solid #848484;
}

.coming-soon-inner .form-control {
  width: 69%;
  height: 42px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 13px;
  outline: 0;
  border: 1px solid #ebebeb;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.coming-soon-inner .btn,
.coming-soon-inner .btn:hover,
.coming-soon-inner .btn:active {
  width: 31%;
  border-radius: 0;
  height: 42px;
  line-height: 42px;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
}

.coming-soon-counter div {
  display: inline-block;
  font-size: 40px;
  float: right;
  font-weight: 700;
  text-align: center;
  margin: 0 0 20px 20px;
  padding: 10px 0 15px;
  width: 30%;
  background: rgba(0, 0, 0, 0.7);
  text-shadow: 2px 2px rgba(12, 11, 11, 0.56);
  border-radius: 5px;
}

.coming-soon-counter div span {
  display: block;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}

/** OVERVIEW START **/
.overview p {
  font-family: Georgia, serif;
  font-size: 14px;
  position: relative;
  color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
}

.overview-box p {
  color: #333;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.overview {
  z-index: 9;
  cursor: default;
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 16px;
  box-shadow: 0 0 3px #ddd;
}

.overview .mask,
.overview .content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 30%;
  left: 0;
}

.overview img {
  display: block;
  position: relative;
  width: 100%;
  height: 232px;
}

.overview .big-img {
  height: 480px;
}

.overview-box img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  border: none;
}

.overview-box:hover > img {
  -webkit-transform: scale(2) rotate(10deg);
  transform: scale(2) rotate(10deg);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}

.overview h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 16px;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.8);
  text-shadow: none;
}

.overview-box h2 {
  color: #333;
  margin: 0 20px 0;
  background: transparent;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

.overview a.btn {
  display: inline-block;
  overflow: hidden;
  text-transform: uppercase;
}

.overview-box a.btn {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.5s linear;
  padding: 4px 20px;
}

.overview-box:hover h2,
.overview-box:hover p,
.overview-box:hover a.btn {
  -webkit-transform: scale(1);
  transform: scale(1);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.most-popular-places .col-pad {
  padding: 0 8px;
}

/** FULL PAGE SEARCH **/
#full-page-search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  z-index: 99999;
}

#full-page-search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
}

#full-page-search input[type="search"] {
  position: absolute;
  top: 50%;
  width: 100%;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0);
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 28px;
}

#full-page-search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
}

#full-page-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 1;
  padding: 10px 17px;
  font-size: 17px;
  outline: 0 auto -webkit-focus-ring-color;
  cursor: pointer;
  border-radius: 3px;
  background: #3a3a3a;
}

/** PORTFOLIO AREA START **/
.filteriz-navigation {
  padding: 0;
  margin: 0 0 26px 0;
  text-align: center;
}

.filteriz-navigation li {
  color: #535353;
  text-align: center;
  letter-spacing: 1px;
  padding: 5px 20px;
  margin: 0 2px 4px;
  border: 0;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.portfolio-item,
.portfolio-item:focus {
  outline: none;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
}

.portfolio-item img,
.portfolio-item img:focus {
  outline: none;
  width: 100%;
  height: 100%;
}

.portfolio-item:hover img {
  zoom: 1;
  filter: alpha(opacity=20);
  -webkit-opacity: 0.1;
  opacity: 0.1;
}

.portfolio-item:hover .portfolio-content {
  display: inline;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff !important;
  z-index: -999;
}

.portfolio-content {
  display: none;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-transform: uppercase;
  color: #1c1c1c;
  height: 100%;
  position: absolute;
  right: 0;
}

.portfolio-content-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.portfolio-content p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
}

.overview-box p {
  color: #333;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.overview {
  z-index: 9;
  cursor: default;
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 16px;
  box-shadow: 0 0 3px #ddd;
}

.overview .mask,
.overview .content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 30%;
  left: 0;
}

.overview img {
  display: block;
  position: relative;
  width: 100%;
  height: 232px;
}

.overview .big-img {
  height: 480px;
}

.overview-box img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  border: none;
}

.overview-box:hover > img {
  -webkit-transform: scale(2) rotate(10deg);
  transform: scale(2) rotate(10deg);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}

.overview h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 18px;
  padding: 0 10px 5px;
  background: rgba(0, 0, 0, 0.8);
  margin: 20px 0 0 0;
  text-shadow: none;
}

.overview-box h2 {
  color: #333;
  margin: 0 20px 0;
  background: transparent;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

.overview a.btn {
  display: inline-block;
  overflow: hidden;
  text-transform: uppercase;
}

.overview-box a.btn {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.5s linear;
  padding: 4px 20px;
}

.overview-box:hover h2,
.overview-box:hover p,
.overview-box:hover a.btn {
  -webkit-transform: scale(1);
  transform: scale(1);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.most-popular-places .col-pad {
  padding: 0 8px;
}

/** VIEW ALL**/
.view-all ul {
  margin-bottom: 30px;
  text-align: center;
}

.view-all ul li {
  display: inline-block;
}

.view-all ul li a {
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.view-all ul li a:hover {
  color: #fff;
}

.view-all h3 {
  margin-bottom: 30px;
  text-align: center;
}

.view-section .form-search .form-control {
  border: 1px solid #eee;
  height: 45px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

/** BRAND BOX**/
.brand-box {
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.brand-box:hover {
  box-shadow: none;
  background: #eee;
}

.brand-box h5 {
  font-weight: 500;
  margin: 10px 0 0;
  font-size: 14px;
}

.brand-box img {
  height: 50px;
}

/** SEARCH AREA 1**/
#search-area-1 {
  background: #e6e6e6;
  padding: 35px 0 5px;
}

#search-area-2 {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  z-index: 999;
  padding: 40px 0 10px;
}

#search-area-3 {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 999;
  padding: 40px 0 10px;
}

#search-area-4 {
  transition: all 0.4s;
  position: absolute;
  bottom: -84px;
  width: 100%;
}

#search-area-4 .search-area-inner {
  padding: 30px 30px 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
}

.search-area .search-fields {
  min-height: 45px;
  padding: 5px 5px 2px 0;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 3px;
  width: 100% !important;
  cursor: pointer;
}

.form-group label,
.checkbox label {
  font-size: 12px;
  font-weight: 500;
  color: #535353;
}

.search-area .search-fields button {
  border-radius: 1px;
  box-shadow: none;
  border: none;
  background: #fff;
}

.search-area .search-button {
  cursor: pointer;
  padding: 13px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  transition: 0.5s;
  border-radius: 2px;
  border: none;
  width: 100%;
}

.search-area .search-button:hover,
.search-area .search-button:focus {
  outline: none;
  color: #fff;
  box-shadow: none !important;
}

.search-area .selectBox-dropdown .selectBox-arrow {
  text-align: center;
  width: 36px;
}

.search-area .selectBox-dropdown .selectBox-arrow:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f078";
  line-height: 44px;
  color: #777;
  font-size: 12px;
}

.search-area .selectBox-dropdown .selectBox-label {
  padding: 2px 15px;
  display: initial;
  white-space: nowrap;
  overflow: hidden;
  line-height: 37px;
  font-size: 14px;
  color: #888;
  font-weight: 400;
}

.search-area .selectBox-options.selectBox-options-bottom {
  margin: 0;
  padding: 0;
}

.search-area .selectBox-options,
.selectBox-options li a {
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}

.search-area .selectBox-options,
.selectBox-options li:last-child {
  border-bottom: none;
}

/** SEARCH AREA 2 **/
.search-area-2 {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  z-index: 999;
  padding: 40px 0 10px;
}

.search-area-2 .search-fields {
  border: 1px solid #b1b1b1;
  background: transparent;
}

.search-area-2 .selectBox-dropdown .selectBox-label {
  color: #b1b1b1;
}

.search-area-2 .selectBox-dropdown .selectBox-arrow:before {
  color: #b1b1b1;
}

/** MAP classes **/
.search-area-3 {
  position: absolute;
  background-color: #fff;
  padding: 25px 25px 10px;
  max-width: 300px;
  width: 100%;
  top: 25%;
  right: 8%;
  z-index: 1;
  border-radius: 3px;
}

.search-area-3 h2 {
  margin-bottom: 0;
  font-size: 20px;
}

.search-area-3 .search-fields {
  background: transparent;
}

.search-area-3 .selectBox-dropdown .selectBox-label {
  color: #b1b1b1;
}

.search-area-3 .selectBox-dropdown .selectBox-arrow:before {
  color: #b1b1b1;
}

/** MAP classes **/
.map-content {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/** MAP **/
#contactMap {
  width: 100%;
  height: 350px;
}

.map {
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 100%;
}

.contact-map-content .map-content {
  width: 250px;
}

.marker-loaded .map-marker {
  visibility: visible;
}

.map-properties .map-properties-list {
  margin: 0;
  padding: 0;
}

.map-properties .map-properties-list li {
  list-style: none;
  margin-bottom: 8px;
  color: #999;
}

.map-properties .map-properties-list li i {
  margin-right: 10px;
}

.cnt-bg-photo {
  background-size: cover;
  top: 0;
  min-height: 100vh;
  width: 100%;
  bottom: 0;
  max-height: 100%;
}

/** OFF-CANVAS MENU **/
body.off-canvas-sidebar-open {
  left: -375px;
  position: relative;
}

.off-canvas-sidebar-open .off-canvas-sidebar {
  -webkit-transform: translateX(-375px);
  transform: translateX(-375px);
}

.off-canvas-sidebar {
  background: #1b1d1f;
  position: fixed;
  right: -375px;
  top: 0;
  height: 100%;
  width: 375px;
  z-index: 999999;
  -webkit-transition: -webkit-transform 0.2s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.off-canvas-sidebar-wrapper {
  width: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.off-canvas-content,
.off-canvas-header {
  padding-right: 30px;
  padding-left: 30px;
}

.off-canvas-content {
  padding-bottom: 30px;
}

.off-canvas-header {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: right;
}

.canvas-widget .menu {
  text-align: center;
  margin-top: 50px;
}

.canvas-widget .menu-item {
  margin-bottom: 25px;
}

.canvas-widget .menu-item a {
  color: #fff;
  line-height: 1.2;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.canvas-widget .menu-item a:hover {
  opacity: 0.8;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.canvas-widget .logo {
}

.canvas-widget .social-icons {
  text-align: center;
  margin-top: 80px;
}

.canvas-widget .social-icons > li {
  display: inline-block;
  margin: 10px;
}

.canvas-widget .social-icons > li a {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.canvas-widget .social-icons > li a:hover {
  opacity: 0.8;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.close-offcanvas {
  font-size: 24px;
}

.bbb {
  background: none !important;
}

/** Slick slider start**/
.slick-slider-area {
  position: relative;
}

.slick-slide-item {
  padding: 0 15px;
}

.slick-arrow-buton {
  position: absolute;
  top: 43%;
}

.slick-arrow-buton {
  width: 35px;
  height: 35px;
  line-height: 35px;
  top: 43%;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  text-align: center;
  background: #ccc;
}

.slick-next {
  right: -15px;
}

.slick-prev {
  left: -15px;
}

.slick-slide,
.slick-slide:focus * {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.simple-slick-carousel.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.simple-slick-carousel.slick-dotted.slick-slider.dots-nav {
  margin-bottom: 30px;
}

.simple-slick-carousel .slick-dots {
  display: none !important;
}

.simple-slick-carousel.dots-nav .slick-dots {
  display: block !important;
}

.slick-dots li {
  position: relative;
  padding: 0;
  margin: 0 3px;
  display: inline-block;
  cursor: pointer;
}

.slick-dots li {
  width: 16px;
  height: 16px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  position: relative;
  outline: none;
  display: inline-block;
  margin: 0 3px;
  opacity: 1;
  border-radius: 50%;
  line-height: 0;
  box-shadow: inset 0 0 0 2px #777;
  transition: all 0.3s;
}

.slick-dots li button {
  display: none;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li.slick-active {
  transition: all 0.3s;
}

.slick-dots li:after {
  position: absolute;
  height: 13px;
  width: 13px;
  display: block;
  content: "";
  top: 1px;
  left: 1px;
  border-radius: 50%;
  transition: all 0.3s;
  opacity: 0;
}

.slick-dots li.slick-active:after {
  opacity: 1;
}
/** Slick slider end**/

/** MEDIA **/
@media (max-width: 1200px) {
  .property-box-3 .media-body {
    padding-right: 0;
  }

  .blog-4 .detail {
    padding: 20px 0 20px 20px;
  }

  .blog-4 .detail .post-meta span a i {
    margin-right: 5px;
  }

  .blog-4 .detail p {
    font-size: 14px;
    line-height: 22px;
  }

  .blog-4 .detail h4 {
    margin-bottom: 15px;
    font-size: 17px;
  }

  .coming-soon-inner .coming-form {
    max-width: 85%;
  }

  .content-form-box {
    padding: 40px 20px;
    height: inherit;
  }

  .content-form-box.forgot-box {
    margin: 50px auto 10px;
    border-radius: 3px;
    padding: 30px;
  }

  .search-area-3 {
    top: 22%;
  }

  .coming-soon-inner .cm {
    top: 25%;
  }

  .coming-soon-inner h1 {
    font-size: 80px;
  }

  .property-box-3 h3 {
    font-size: 17px;
  }

  .property-box-5 .detail {
    padding: 20px 27px 20px 20px;
  }

  .property-box-5 ul li i {
    margin-right: 0;
    font-size: 13px;
  }

  .property-box-5 ul li {
    font-size: 13px;
    margin-right: 10px;
  }

  .property-box-5 .detail .location {
    margin: 0 0 10px;
  }

  .property-box-6 h3 {
    margin: 0 0 15px;
    font-size: 22px;
  }

  .property-box-6 p {
    margin-bottom: 15px;
  }

  .property-box-6 .info {
    padding: 0 30px;
  }

  .comparison .heading h3 {
    font-size: 19px;
  }

  .banner .banner-slider-inner .typed-text {
    font-size: 40px;
  }

  .agent-5 .media-body {
    padding-right: 0;
  }

  .agent-5 h3 {
    font-size: 19px;
  }

  .main-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .open-offcanvas {
    padding: 18px 15px 15px;
  }

  .blog-4 .detail {
    padding: 25px 20px 20px 20px;
    left: -0;
  }

  .blog-4 img {
    width: 100%;
  }

  .blog-4 .detail p {
    line-height: 26px;
  }

  .blog-4 .detail h4 {
    font-size: 19px;
  }

  .blog-3 .photo img {
    width: 100%;
  }

  .blog-3 .detail {
    padding: 20px 20px;
  }

  .coming-soon-counter div {
    width: 40%;
  }

  .coming-soon-inner h1 {
    font-size: 60px;
  }
  .megamenu-section p {
    font-size: 12px;
  }

  .services-info-3.s-brd-2 {
    border-bottom: none;
  }

  .services-info-3.s-brd-1 {
    border: 1px solid #f1f2f3;
    border-bottom: none;
  }

  .compare-properties h5 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .map-content-left {
    padding: 20px 30px 20px 30px;
  }

  .map-content-right {
    padding: 20px 30px 20px 30px;
  }

  .recent-posts .media img {
    margin-right: 15px;
  }

  .agent-5 img {
    width: 130px;
    height: 130px;
  }

  .property-box-3 img {
    width: 110px;
    height: 110px;
  }

  #search-area-1 .form-group {
    margin-bottom: 10px;
  }

  #search-area-1 .selectBox-dropdown .selectBox-arrow {
    margin-right: 10px;
    width: auto;
  }

  #search-area-1 .selectBox-dropdown .selectBox-arrow {
    border-left: none;
  }

  #search-area-1 {
    padding: 30px 0 0;
  }

  #search-area-1 .range-slider {
    margin-top: 9px;
    margin-bottom: 30px;
  }

  #search-area-1 .selectBox-dropdown .selectBox-arrow:before {
    line-height: 37px;
    font-size: 10px;
  }

  #search-area-1 .selectBox-dropdown .selectBox-label {
    padding: 2px 10px;
    font-size: 13px;
  }

  .text-l {
    text-align: center;
  }

  .banner .banner-slider-inner h2 {
    font-size: 35px;
  }

  .navbar-collapse {
    margin: 0px -15px;
    background: #fff;
  }

  .footer {
    padding-top: 80px;
  }

  .footer .copy {
    margin: 30px 0 0;
  }

  .hidden-mb-45 {
    margin-bottom: 45px;
  }

  .comparison .heading h3 {
    text-align: center;
  }

  .comparison .heading {
    margin-bottom: 20px;
  }

  #main-header .navbar-expand-lg .navbar-nav .nav-link {
    color: #000;
  }

  .main-header .navbar-expand-lg .navbar-nav .active .nav-link {
    background: #f9f9f9;
    border-bottom: solid 1px transparent;
  }

  .main-header .navbar-expand-lg .navbar-nav .nav-link:hover {
    background: #f9f9f9;
    border-bottom: solid 1px transparent;
  }

  .content-area {
    padding-bottom: 50px;
  }

  .content-area-2 {
    padding: 80px 0 50px;
  }

  .content-area-3 {
    padding-bottom: 64px;
  }

  .content-area-4 {
    padding: 80px 0 30px;
  }

  .content-area-5 {
    padding-bottom: 80px;
  }

  .content-area-6 {
    padding-bottom: 30px;
  }

  .content-area-7 {
    padding: 80px 0;
  }

  .content-area-8 {
    padding-top: 80px;
  }

  .content-area-9 {
    padding: 30px 0;
  }

  .content-area-10 {
    padding: 80px 0 30px;
  }

  .content-area-11 {
    padding: 80px 0 30px;
  }

  .content-area-12 {
    padding-bottom: 30px;
  }

  .content-area-13 {
    padding: 80px 0 30px;
  }

  .content-area-14 {
    padding: 80px 0 30px;
  }

  .content-area-14 {
    padding: 80px 0 60px;
  }

  .content-area-15 {
    padding: 80px 0 50px;
  }

  .content-area-16 {
    padding: 80px 0 50px;
  }

  .content-area-17 {
    margin-bottom: 50px;
  }

  .content-area-18 {
    padding: 80px 0 20px;
  }

  .content-area-19 {
    padding: 80px 0 50px;
  }

  .content-area-20 {
    padding: 80px 0;
  }

  .submit-property .mb-50 {
    margin-bottom: 35px;
  }

  .submit-property .heading {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .services-info-2 i {
    font-size: 30px;
  }

  .services-info-2 h5 {
    font-size: 16px;
    margin: 10px 0 10px;
  }

  .main-title {
    margin-bottom: 30px;
  }

  .main-title h1 {
    font-size: 30px;
  }

  .main-title p {
    font-size: 17px;
  }

  .testimonial {
    padding: 60px 0 50px;
  }

  .testimonial .testimonial-inner {
    margin: 0 auto;
    max-width: 100%;
  }

  .testimonial .testimonial-inner .testimonia-header h1 {
    font-size: 30px;
  }

  .testimonial-2 .testimonia-header {
    margin: 0 auto 30px;
  }

  .testimonial-2 .testimonial-inner .testimonia-header h1 {
    font-size: 30px;
  }

  .testimonial .testimonial-inner .lead {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
  }

  .testimonial .testimonia-header {
    margin: 0 auto 30px;
  }

  .testimonial .testimonia-header h1 {
    font-size: 30px;
  }

  .pricing.mrg-btn-60 {
    margin-bottom: 70px;
  }

  .contact-1 .contact-info .media i {
    width: 50px;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
  }

  .properties-slider {
    margin-bottom: 40px;
  }

  .overview img {
    height: auto;
  }

  .services-info i {
    font-size: 30px;
    width: 50px;
  }

  .services-info h5 {
    font-size: 17px;
  }

  .pricing-tables .mrg-btn-76 {
    margin-bottom: 40px;
  }

  .testimonial-2 {
    padding: 80px 0 110px;
  }

  .sidebarmbl {
    margin-left: 0;
  }

  .about-text {
    margin-top: 40px;
  }

  .mrb {
    margin: 0 0 50px 0;
  }

  .mbl {
    margin: 0 0 30px 0;
  }

  .properties-list-fullwidth .property-box-5 .price-ratings-box {
    right: 30px;
  }

  .properties-list-fullwidth .property-box-5 .detail {
    padding: 20px 40px;
  }

  .banner .banner-slider-inner h1 {
    font-size: 40px;
  }

  .banner .slider-mover-left {
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: -40px;
  }

  .banner .slider-mover-left i {
    font-size: 22px;
    line-height: 30px;
  }

  .banner .slider-mover-right {
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: 10px;
  }

  .banner .slider-mover-right i {
    font-size: 22px;
    line-height: 30px;
  }

  .navbar-light .navbar-toggler {
    border: none;
    font-size: 20px;
    font-weight: 100;
  }

  .navbar-toggler {
    padding: 0;
    width: 35px;
    height: 35px;
    font-size: 1rem;
    border-radius: 0;
    outline: none !important;
  }

  .main-header .logo img {
    height: 35px;
  }

  .main-header .navbar-expand-lg .navbar-nav .nav-link {
    padding: 16px 15px 15px;
    font-size: 13px;
    border-bottom: solid 1px transparent;
  }

  .banner .banner-slider-inner {
    padding: 20px 15px;
    top: 0%;
  }

  .banner .banner-slider-inner h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .banner .banner-slider-inner h3 {
    margin-bottom: 20px;
    font-size: 35px;
  }

  .banner .banner-opt .banner-slider-inner h1 {
    font-size: 32px;
  }

  .banner .banner-opt .banner-slider-inner p {
    font-size: 14px;
  }

  .banner .banner-slider-inner p {
    color: #fff;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .banner .banner-slider-inner .text-p {
    margin-bottom: 20px;
  }

  .breadcrumb-area h1 {
    font-size: 35px;
  }

  .shop-infombl {
    margin-left: 0;
  }

  .shop-info {
    margin-top: 40px;
  }

  .media-pad-top-50 {
    padding-top: 50px;
  }

  .register-info {
    padding: 30px 40px;
  }

  .coming-soon-inner .coming-form {
    max-width: 100%;
  }

  .content-form-box {
    padding: 40px 20px;
    height: inherit;
    bottom: 120px;
  }

  .setting-button {
    font-size: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: -40px;
  }

  .search-area .search-fields {
    min-height: 38px;
    padding: 0 5px 0 0;
  }

  .submit-property .contact-2 .form-control {
    padding: 10px 15px;
    height: 38px;
  }

  .search-area .selectBox-dropdown .selectBox-arrow:before {
    line-height: 38px;
  }

  .search-area .search-button {
    cursor: pointer;
    padding: 10px 20px 9px;
  }

  .shop-single .widget.shop-info {
    margin-bottom: 40px;
  }

  .pt-40 {
    padding-top: 40px;
  }

  .sidebar-title {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .sub-banner {
    height: 265px;
  }

  .sub-banner .breadcrumb-area {
    top: 40%;
  }

  .sub-banner .breadcrumb-area h1 {
    margin: 0 0 10px;
    font-size: 30px;
  }

  .sub-banner .breadcrumbs li {
    font-size: 16px;
  }

  .top-header .list-inline a {
    margin-right: 10px;
    font-size: 11px;
  }

  .top-header .top-social-media li {
    padding: 0 4px;
    height: 40px;
    display: inline-block;
    text-align: center;
    font-size: 11px;
  }

  .property-box-5 ul li i {
    margin-right: 8px;
    font-size: 14px;
  }

  .property-box-5 ul li {
    margin-right: 20px;
  }

  .property-box-5 .detail .location {
    margin: 0 0 15px;
  }

  .p-box .price-ratings-box {
    right: 30px;
  }

  .p-box .detail {
    padding: 30px 40px 30px 40px;
  }

  .services-info-4 .detail {
    padding: 30px 15px;
  }

  .hedin-mb-30 {
    margin-bottom: 30px !important;
  }

  .hedin-mb-50 {
    margin-bottom: 50px;
  }

  .widget-2 {
    margin-bottom: 60px;
    background: #fff;
    padding: 20px;
  }

  .simple-content p {
    line-height: 28px;
    font-size: 15px;
  }

  .simple-content h1 {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .simple-content .btn {
    margin: 2px;
  }

  .simple-content {
    padding-top: 60px;
  }

  .simple-content .btn-sm {
    padding: 6px 23px;
    margin: 2px 2px 0;
  }

  .property-box-6 .info {
    padding: 30px;
  }

  .intro-text h3 {
    font-size: 20px;
    line-height: inherit;
  }

  .intro-section img {
    height: 35px;
  }

  .intro-section .btn-md {
    padding: 6px 22px 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .intro-section .btn {
    float: none;
  }

  .counter-box h1 {
    font-size: 36px;
    margin: 15px 0;
  }

  .counter-box i {
    font-size: 30px;
  }

  .faq-accordion .card-header {
    padding: 15px 20px;
  }

  .comparison .faq-accordion .card-title {
    font-size: 15px;
  }

  .btn-lg {
    padding: 11px 32px 9px;
  }

  .btn-white-lg-outline {
    padding: 10px 30px 8px;
  }

  .forgot-box {
    margin: 50px auto 10px;
    border-radius: 3px;
  }
}

@media (max-width: 768px) {
  .content-area {
    padding-bottom: 30px;
  }

  .content-area-2 {
    padding: 60px 0 30px;
  }

  .content-area-3 {
    padding-bottom: 44px;
  }

  .content-area-4 {
    padding: 60px 0 10px;
  }

  .content-area-5 {
    padding-bottom: 60px;
  }

  .content-area-6 {
    padding-bottom: 10px;
  }

  .content-area-7 {
    padding: 60px 0;
  }

  .content-area-8 {
    padding-top: 60px;
  }

  .content-area-9 {
    padding: 10px 0;
  }

  .content-area-10 {
    padding: 60px 0 10px;
  }

  .content-area-11 {
    padding: 60px 0 10px;
  }

  .content-area-12 {
    padding-bottom: 30px;
  }

  .content-area-13 {
    padding: 60px 0 30px;
  }

  .content-area-14 {
    padding: 60px 0 60px;
  }

  .content-area-15 {
    padding: 60px 0 30px;
  }

  .content-area-16 {
    padding: 60px 0 30px;
  }

  .content-area-17 {
    margin-bottom: 30px;
  }

  .content-area-18 {
    padding: 60px 0 20px;
  }

  .content-area-19 {
    padding: 60px 0 30px;
  }

  .content-area-20 {
    padding: 60px 0;
  }

  .agent-4 .details {
    padding: 25px 20px 20px;
  }

  .agent-6 .details h3 {
    margin: 10px 0 10px;
  }

  .agent-6 .details h3 a {
    font-size: 25px;
  }

  .main-title h1 {
    margin-bottom: 10px;
  }

  .blog-3 img {
    height: 350px;
  }

  .blog-4 img {
    height: 350px;
  }

  .content-box {
    margin: 50px auto 10px;
    max-width: 400px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 0;
    display: inline-block;
  }

  .properties-details-sliders .left {
    left: 5px;
  }

  .properties-details-sliders .right {
    right: 5px;
  }

  .svs .services-info-2 {
    margin: 0 auto 30px;
  }

  .typography .mb-50 {
    margin-bottom: 35px;
  }

  .services-info-3.s-brd-3 {
    border-bottom: none;
  }

  .sub-banner .breadcrumb-area h1 {
    font-size: 25px;
  }

  .blog-1 .detail h2 a {
    font-size: 21px;
  }

  .blog-1 .detail .post-meta span {
    margin-right: 15px;
  }

  .sub-banner .breadcrumbs .active {
    margin-left: 5px;
  }

  .sub-banner .breadcrumbs .active:before {
    margin-right: 7px;
  }

  .pagination > li > a,
  .pagination > li > span {
    margin: 0 2px 5px;
    width: 35px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
  }

  .cart-total-box {
    padding: 20px;
  }

  .shop-table.cart td,
  .shop-table.cart th {
    padding-left: 0;
  }

  .shop-table.cart .product-thumbnail,
  .shop-table.cart .product-name a {
    font-size: 13px;
  }

  .notification-box {
    padding: 20px;
  }

  .notification-box h3 {
    font-size: 25px;
  }

  .shop-table.cart .product-thumbnail img {
    width: 70px;
  }

  .faq-accordion .card-header {
    padding: 13px 20px;
  }

  .faq-accordion .card-header a.collapsed:before {
    margin-right: 10px;
  }

  .faq-accordion .card-title {
    font-size: 15px;
  }

  .faq-accordion .card-title {
    padding-right: 0;
  }

  .intro-section {
    padding: 15px 0 20px;
  }

  .testimonial .testimonial-inner .avatar {
    max-width: 100px;
  }

  .filteriz-navigation li {
    padding: 4px 15px;
    margin: 0 1px 4px;
    font-size: 12px;
    font-weight: 500;
  }

  #search-area-1 .selectBox-dropdown .selectBox-label {
    padding: 2px 12px;
    font-size: 13px;
  }

  .btn-white {
    padding: 9px 20px 7px;
    font-size: 13px;
  }

  .banner .banner-slider-inner h2 {
    font-size: 27px;
  }

  .t-center {
    text-align: center;
  }

  .t-right {
    text-align: center;
  }

  .t-left {
    text-align: center;
  }

  .property-modal .modal-header .modal-title {
    font-size: 19px;
  }

  .banner .banner-slider-inner h3 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  .footer {
    padding-top: 60px;
  }

  .footer .copy {
    margin: 0;
  }

  .footer h4 {
    margin-bottom: 20px;
  }

  .option-bar .heading {
    font-size: 14px;
  }

  .main-title {
    margin-bottom: 25px;
  }

  .main-title h1 {
    font-size: 25px;
  }

  .overview .big-img {
    height: auto;
  }

  .contact-2 .contact-info .contact-info {
    margin-bottom: 30px;
  }

  .contact-2 .contact-info i {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .heading-2 h4 {
    font-size: 22px;
  }

  .overview .mask,
  .overview .content {
    top: 35% !important;
  }

  #main-header {
    top: 0;
  }

  .widget-2 {
    margin-bottom: 40px;
  }

  .tabbing-box .tab-pane {
    padding-top: 40px;
  }

  .testimonial .testimonial-inner .testimonia-header h1 {
    font-size: 25px;
  }

  .testimonial .testimonia-header {
    margin: 0 auto 25px;
  }

  .testimonial-2 .testimonial-inner .testimonia-header h1 {
    font-size: 25px;
  }

  .testimonial-2 .testimonial-inner {
    text-align: center;
  }

  .testimonial-2 .testimonia-header {
    margin: 0 auto 25px;
  }

  .testimonial-2 .avatar {
    max-width: 110px;
    margin: 0 auto 20px;
  }

  .testimonial-2 .testimonial-inner .lead {
    font-size: 15px;
    line-height: 28px;
  }

  .contact-1 .contact-info {
    margin-top: 50px;
  }

  .contact-1 .contact-info h3 {
    margin-bottom: 35px;
    font-size: 23px;
  }

  .contact-1 .form-group {
    margin-bottom: 20px;
  }

  .contact-3 .contact-info .contact-list {
    margin: 0 0 40px;
  }

  .contact-3 .contact-info h3 {
    margin-bottom: 20px;
    font-size: 23px;
  }

  .pages-404 .pages-404-inner h1 {
    font-size: 130px;
    font-weight: 500;
  }

  .pages-404 .pages-404-inner h5 {
    font-size: 22px;
  }

  .property-box-5 .price-ratings-box {
    right: 30px;
  }

  .property-box-5 .col-pad {
    padding: 0 15px;
  }

  .property-box-5 .detail {
    padding: 30px 20px;
  }

  .property-box-5 ul li {
    line-height: 30px;
  }

  .services .col-pad-30 {
    padding: 30px;
  }

  .comments ul {
    margin: 0;
  }

  .comment {
    margin: 0 0 40px 0;
  }

  .comment .comment-content {
    margin-left: 80px;
  }

  .tags-socal-box .social-list {
    float: none;
    text-align: left;
    margin-top: 20px;
  }

  .property-box ul li {
    font-size: 13px;
  }

  .pricing-tables .mrg-btn-76 {
    margin-bottom: 30px;
  }

  .testimonial-2 {
    padding: 60px 0 90px;
  }

  .property-box-5 .tag.featured {
    left: 15px;
    margin-top: 15px;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .property-box-5 .price-ratings-box {
    right: 15px;
  }

  .properties-list-fullwidth .property-box-5 .price-ratings-box {
    right: 15px;
  }

  .properties-list-fullwidth .property-box-5 .detail {
    padding: 20px 20px;
  }

  .banner .banner-slider-inner h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .banner .banner-slider-inner {
    padding: 10px 12px;
    top: 0%;
  }

  .banner .banner-opt .banner-slider-inner h1 {
    font-size: 20px;
  }

  .banner .banner-opt .banner-slider-inner p {
    line-height: 22px;
    font-size: 14px;
    max-width: 90%;
    margin: 0px auto;
    margin-bottom: 5px;
  }

  .banner .btn-md {
    padding: 9px 15px;
    font-size: 12px;
  }

  .btn-lg {
    padding: 7px 25px 5px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .btn-white-lg-outline {
    padding: 5px 23px 3px;
  }

  .breadcrumb-area h1 {
    font-size: 28px;
  }

  .sub-banner .breadcrumbs li {
    font-size: 16px;
  }

  button:focus {
    outline: 0 auto -webkit-focus-ring-color;
  }

  .banner .slider-mover-right i {
    font-size: 20px;
    line-height: 40px;
  }

  .banner .slider-mover-left i {
    font-size: 20px;
    line-height: 40px;
  }

  .banner .slider-mover-right {
    width: 20px;
    height: 40px;
    line-height: 40px;
    right: 0;
    border-radius: 5px 0 0 5px;
  }

  .banner .slider-mover-left {
    width: 20px;
    height: 40px;
    line-height: 40px;
    right: -20px;
    border-radius: 0 5px 5px 0;
  }

  .shop-single .shop-info h3 {
    font-size: 20px;
  }

  .heading-properties h3 {
    font-size: 20px;
  }

  .heading-properties p {
    font-size: 13px;
  }

  .coming-soon-counter div {
    font-size: 30px;
    width: 21%;
    margin: 0 5px 30px 10px;
    float: none;
  }

  .coming-soon-inner {
    text-align: center;
  }

  .coming-soon-counter div span {
    font-size: 12px;
  }

  .coming-soon-inner .coming-form {
    max-width: 70%;
    margin: 0 15% 20px;
  }

  .coming-soon-inner .form-control {
    width: 100%;
    border-radius: 3px;
  }

  .coming-soon-inner .btn {
    width: 50%;
    margin: auto;
    margin-top: 10px;
    border-radius: 3px;
  }

  .coming-soon-inner .btn:hover {
    width: 50%;
    border-radius: 3px;
  }

  .coming-soon-inner h1 {
    font-size: 35px;
  }

  .coming-soon-inner .cm {
    position: absolute;
    top: 15%;
    left: 3%;
    width: 94%;
    right: 3%;
  }

  .coming-soon-inner ul li {
    margin-left: 5px;
    float: none;
  }

  .register-box {
    margin: auto;
  }

  .content-form-box {
    position: static;
    left: 0;
    bottom: 40px;
    padding: 30px 30px;
    display: inherit;
    max-width: 450px;
  }

  .content-form-box.register-box {
    margin: 50px auto 10px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding-bottom: 0;
  }

  .login-footer {
    padding: 20px 15px;
    position: inherit;
    left: 0;
    bottom: 0;
  }

  .blog-1 .date-box {
    top: 12px;
    right: 12px;
    padding: 8px;
  }

  .blog-1 .date-box h5 {
    font-size: 11px;
  }

  .compare-properties p i {
    font-size: 12px;
  }

  .setting-button {
    font-size: 14px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    left: -35px;
    color: #fff;
  }

  .blog-1 .detail .post-meta {
    margin: 10px 0 15px;
    font-size: 12px;
  }

  .tabbing-box .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 9px 13px;
  }

  .tabbing-box .nav-tabs li {
    font-size: 11px;
  }

  .sub-banner {
    height: 240px;
  }

  #search-area-1 {
    padding: 30px 0 0;
  }

  .property-description.pt-40 {
    padding-top: 20px;
  }

  .widget {
    margin-bottom: 0;
  }

  .hdn-mb-30 {
    margin-bottom: 30px;
  }

  .properties-details-page .mb-60 {
    margin-bottom: 40px;
  }

  .helping-center {
    margin: 20px 0;
  }

  .hedin-mb-30 {
    margin-bottom: 10px !important;
  }

  .sell-your-properties {
    margin: 20px 0;
  }

  .properties-details-page .heading {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .simple-content img {
    margin-top: 50px;
  }

  .simple-content {
    text-align: center;
  }

  .property-box-6 .info {
    padding: 30px 20px;
  }

  .intro-text h3 {
    margin: 5px 0 10px;
  }

  .intro-text h3 {
    font-size: 17px;
  }

  .intro-section {
    text-align: center;
  }

  .my-properties table tbody tr td.image {
    width: 30px;
  }

  .my-properties table tbody tr td.image img {
    width: 100%;
  }

  .my-properties table thead tr th:first-child {
    padding-left: 10px;
  }

  .my-properties table thead tr th {
    font-size: 12px;
    font-weight: 500;
  }

  .my-properties table h2 {
    border: none;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 7px 0;
    padding: 0;
  }

  .my-properties table tbody tr td {
    padding: 20px 10px 20px 0;
    font-size: 13px;
  }

  .option-bar .heading-icon {
    margin-right: 10px;
  }

  .banner .carousel-content {
    padding: 0 25px;
  }

  .banner .banner-slider-inner .typed-text {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .setting-button {
    display: none;
  }
}

@media (max-width: 500px) {
  .banner img {
    height: 270px;
  }

  .coming-soon-counter div {
    margin: 0 3px 15px;
  }

  .hdn {
    display: none;
  }

  .btn-lg {
    padding: 4px 17px;
    letter-spacing: 0.7px;
    font-size: 11px !important;
  }

  .btn-white-lg-outline {
    padding: 3px 17px;
    font-size: 11px !important;
  }

  .main-header .logo img {
    height: 30px;
  }

  .main-header .logo {
    padding: 12px 0;
    margin-right: 20px;
  }

  .banner .banner-slider-inner {
    padding: 10px 10px;
    top: 0%;
  }

  .navbar-toggler {
    width: 30px;
    height: 30px;
  }

  .banner .btn-md {
    padding: 7px 12px;
    font-size: 10px;
  }

  .banner .banner-slider-inner .btn {
    margin: 0 0 5px;
  }

  .banner .banner-slider-inner h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .banner .banner-slider-inner p {
    margin-bottom: 15px;
  }

  .banner .banner-slider-inner .text-p {
    margin-bottom: 15px;
  }

  .banner .banner-opt .banner-slider-inner h1 {
    font-size: 20px;
  }

  .banner .banner-opt .banner-slider-inner p {
    line-height: 22px;
    font-size: 14px;
    max-width: 90%;
    margin: 0px auto;
    margin-bottom: 5px;
  }

  .sub-banner {
    height: 270px;
  }

  .breadcrumb-area h1 {
    font-size: 24px;
  }

  .sub-banner .breadcrumbs li {
    font-size: 14px;
  }

  .sidebar {
    background: transparent;
  }

  .media-pad-top-50 {
    padding-top: 50px;
  }

  .carousel-inner img {
    width: 100%;
  }

  .widget {
    margin-bottom: 40px;
    padding: 0;
    background: transparent;
  }

  .widget-2 {
    margin-bottom: 40px;
    padding: 0;
    background: transparent;
  }

  .sidebar {
    background: transparent;
  }

  .sell-your-properties {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: 30px 20px;
    width: 100%;
    margin: 20px 0 40px;
  }

  .helping-center {
    background: rgba(0, 0, 0, 0.04) url(../../assets/img/bg-photo-2.jpg) top
      left repeat;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px;
    width: 100%;
  }

  .sub-banner {
    height: 200px;
  }
}

@media (min-width: 991px) {
  .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .dropdown .dropdown-item:hover > .dropdown-menu {
    display: block;
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }

  .sticky-header {
    position: fixed;
    background: transparent;
    top: 0px;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, 0.2);
  }

  .header-with-top {
    top: 40px;
  }

  .sticky-header .nav-link::after {
    display: none !important;
  }

  .sticky-header .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
  }

  .sticky-header .open-offcanvas {
    color: #fff;
    display: inline-block;
  }

  .header-shrink {
    background: #fff;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    top: 0px !important;
  }

  .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
    color: #515151;
  }

  .header-shrink .open-offcanvas {
    color: #515151;
  }

  .hide-carat .nav-link::after {
    display: none !important;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .banner .banner-slider-inner h1 {
    margin-bottom: 30px;
    font-size: 50px;
    margin-top: 44px;
  }

  .property-box ul li i {
    margin-right: 5px;
  }

  .blog-3 .detail .post-meta {
    margin: 10px 0;
  }

  .blog-3 .detail p {
    line-height: 24px;
  }

  .blog-3 .detail h3 {
    margin-bottom: 10px;
  }

  .blog-3 .detail h3 a {
    font-size: 18px;
  }

  .property-box-5 .detail p {
    margin-bottom: 5px;
  }

  .banner .banner-slider-inner p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .property-box-2 .detail {
    padding: 20px 15px;
  }

  .property-box-2 .detail h5 a {
    font-size: 17px;
  }
}
