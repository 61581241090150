:root {
  --primaryColor: #71db77;
  --altPrimaryColor: #050d21;
  --greyP: #a2a6ad;
  --fontRadikalBold: "Radikal-bd";
  --fontGothamRG: "Gotham-rg";
  --fontRadikal: "Radikal-rg";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
  --fontUbuntuMD: "Ubuntu-Medium";
  --fontUbuntuBD: "Ubuntu-Bold";
  --fontAllianceExtra: "Alliance-ExtraBold";
}

.login-wrapper {
  font-size: 14px;
  min-height: 100vh;
  overflow: hidden;
  p {
    color: #484848;
  }

  .click {
    cursor: pointer;
    // pointer-events: ;
  }

  .text-red {
    color: #ff3057 !important;
  }
  .text-primary {
    color: var(--primaryColor) !important;
  }

  .login-content {
    padding: 40px 45px;
    position: relative;
    overflow: scroll;
    height: 100vh;
    // background:url('../../assets/img/bg-love.png') no-repeat center;
    // background-size: cover;
    .logo img {
      max-width: 100%;
      width: 150px;
      // filter: brightness(0);
      @media (max-width: 992px) {
        display: none;
      }
    }
    .login-header {
      padding: 75px 0 50px;
      font-family: "Gotham-rg";
      h3 {
        font-weight: 700;
      }
      p {
        margin: 0;
        color: #9fa2b0;
      }
    }

    .form-control:focus {
      outline: none !important;
      border: 1px solid var(--primaryColor);
      box-shadow: 0 0 10px #719ece;
    }

    .footer-content {
      position: static;
    }

    @media (min-width: 320px) and (max-width: 768px) {
      padding: 20px 15px 20px;
      height: auto;

      .logo {
        text-align: center;
      }
      .login-header {
        padding: 40px 0;
        padding-top: 13vh;
      }
      .login-form .form-control {
        width: 100%;
      }
    }

    @media (min-width: 1024px) {
      .footer-content {
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
      }
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      .login-content {
        padding: 40px 15px;
      }
    }
  }
  .right-section {
    height: 100vh;
    &.register-bg {
      background: linear-gradient(
          180deg,
          rgba(113, 219, 119, 0.58) 0%,
          rgba(113, 219, 119, 0.24) 72.4%
        ),
        url("../../assets/img/house1.jpg") no-repeat center center;
      background-size: cover;
    }
    &.login-bg {
      background: linear-gradient(
          180deg,
          rgba(113, 219, 119, 0.58) 0%,
          rgba(113, 219, 119, 0.24) 72.4%
        ),
        url("../../assets/img/bg1.jpg") no-repeat center center;
      background-size: cover;
    }
  }

  @media (min-width: 992px) {
    .right-section {
      display: flex !important;
      flex-direction: column;
    }
  }
  .check-container {
    .check {
      input:checked {
        background-color: var(--primaryColor);
      }
    }
    .checkmark {
      top: 11px;
      left: 13px;
    }
    input:checked ~ .checkmark {
      background-color: pink;
    }
  }
  .btn {
    box-shadow: 2px 2px 2px 2px rgba(159, 162, 176, 0.3);
    padding: 0.5rem 2.8rem !important;
    stroke: none;
    transition: 0.3s ease all;
    &:hover {
      transform: translateY(-2px);
    }
  }
  .btn-red {
    //   background: linear-gradient(90deg, rgba(220,68,127,1) 0%, rgba(248,123,67,1) 99%);
    background: #eb5424;
  }

  .btn-primary {
    background: var(--primaryColor);
    padding: 0;
    border: none;
  }
  .btn-no-bg {
    border: none;
    background: transparent;
    color: var(--primaryColor);
  }
  .btn-outline-red {
    color: linear-gradient(
      90deg,
      rgba(220, 68, 127, 1) 0%,
      rgba(248, 123, 67, 1) 99%
    );
    border: solid 0;
    border-color: linear-gradient(
      90deg,
      rgba(220, 68, 127, 1) 0%,
      rgba(248, 123, 67, 1) 99%
    );
  }
  .remember {
    color: #9395ac;
  }
  .text-grey {
    color: #9fa2b0;
  }

  .modal-title {
    color: #203050;
  }
  .modal-body {
    padding: 1.5rem 2.3rem;
    @media (max-width: 770px) {
      padding: 1.5rem;
    }
  }
  .small-footer {
    p {
      color: white;
      a {
        color: white;
      }
    }
  }
}
