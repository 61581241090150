@font-face {
  font-family: "Radikal-bd";
  src: url("./assets/font/Radikal-Bold.ttf");
}

@font-face {
  font-family: "Alliance-ExtraBold";
  src: url("./assets/font/AllianceExtraBold.ttf");
}

@font-face {
  font-family: "Radikal-rg";
  src: url("./assets/font/Radikal-Regular.ttf");
}

@font-face {
  font-family: "Gotham-rg";
  src: url("./assets/font/GothaProReg.otf");
}

@font-face {
  font-family: "SF-mono";
  src: url("./assets/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("./assets/font/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("./assets/font/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: url("./assets/font/Ubuntu-Medium.ttf");
}

@font-face {
  font-family: "Ubuntu-Bold";
  src: url("./assets/font/Ubuntu-Bold.ttf");
}

:root {
  --primaryColor: #71db77;
  --altPrimaryColor: #050d21;
  --greyP: #a2a6ad;
  --fontRadikalBold: "Radikal-bd";
  --fontGothamRG: "Gotham-rg";
  --fontRadikal: "Radikal-rg";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
  --fontUbuntuMD: "Ubuntu-Medium";
  --fontUbuntuBD: "Ubuntu-Bold";
  --fontAllianceExtra: "Alliance-ExtraBold";
}

*body {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  @media (max-width: 992px) {
    display: none;
  }
  @media (min-width: 992px) {
    // display: inline;
  }

  &.fixed-top {
    background: white;

    .navlink {
      color: var(--altPrimaryColor);

      .signup {
        color: var(--altPrimaryColor);

        &:hover {
          border: 1px solid rgb(36, 36, 36);
          // color: rgb(36, 36, 36);
        }
      }
    }
    .nav-logo {
      a {
        color: wheat;
        font-size: 20px;
        white-space: nowrap;
      }
      img {
        height: 50px;
        object-fit: contain;
        // filter: brightness(0);
      }
    }
  }
  .navlink {
    color: #fff;
    font-size: 13px;
    margin-left: 27px;
    transition: 0.3s ease all;
    .signup {
      font-size: 12px;
      text-transform: uppercase;
      border: 1px solid rgb(205, 210, 212);
      background: none;
      // color: rgb(36, 36, 36);
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      font-weight: 600;
      padding: 5px 10px 3px;
      height: 2rem;
      border-radius: 4px;
      transition: 0.3s ease all;
      &:hover {
        border: 1px solid rgb(36, 36, 36);
        // color: rgb(36, 36, 36);
      }
    }
    &.login {
      font-size: 12px;
      text-transform: uppercase;
      height: 2rem;
      letter-spacing: 1px;
      font-weight: 600;
      transition: 0.3s ease all;
      &:hover {
        color: rgb(36, 36, 36);
      }
    }
  }
  .nav-logo {
    a {
      color: wheat;
      font-size: 20px;
      white-space: nowrap;
    }
    img {
      height: 50px;
      object-fit: contain;
      // filter: brightness(50);
    }
  }
  &.navbarWh {
    .navlink {
      color: var(--altPrimaryColor);

      .signup {
        color: var(--altPrimaryColor);

        &:hover {
          border: 1px solid rgb(36, 36, 36);
          // color: rgb(36, 36, 36);
        }
      }
    }
    .nav-logo {
      a {
        color: wheat;
        font-size: 20px;
        white-space: nowrap;
      }
      img {
        height: 50px;
        object-fit: contain;
        // filter: brightness(0);
      }
    }
  }
}

.main-wrapper {
  .main-container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
      url("./assets/img/bg1.jpg");
    background-size: cover;
  }

  .modalss {
    header2 {
      font-size: 6.5vh;
      color: #f6f6f6;
    }
  }

  header {
    min-height: 70vh;

    display: flex;
    flex-direction: column;
    background-position-x: 100%;
    padding: 12vh 5vw 0vh 5vw;

    @media (min-width: 993px) {
      // border-radius: 5vh;
      // margin: 2vh 4vw;
    }
    p {
      color: #f6f6f6;
    }
    .main-head {
      padding-top: 10vh;
      @media (max-width: 770px) {
        padding-top: 13vh;
      }
    }
    .header-img {
      width: 11.8rem;
      // border-radius: 17px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 6.5vh;
      line-height: 1.3;
      // margin-bottom: 2rem;
      margin-top: 4rem;
      margin-bottom: 9vh;
      font-family: "Radikal-bd";
      text-align: center;
    }
  }
}
.search-box {
  background-color: white;
  border-radius: 2.5vh;
  margin-top: -4vh;
  padding: 3vh 4vw 3vh 4vw;
  // display: flex;
  // width: 75%;

  @media (max-width: 800px) {
    // margin-top: 40px;
  }
  @media (max-width: 600px) {
    // margin-bottom: 8vh;
    // height: 8vh;
    // border-radius: 0px;
  }
  .box-head {
    color: var(--greyP);
    font-family: var(--fontUbuntuLT);
    margin-bottom: 0px;
    font-size: 1.8vh;

    //Breakline 1
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* number of lines to show */
  }
  .box-subtitle {
    color: var(--altPrimaryColor);
    font-family: var(--fontUbuntuMD);
    font-size: 2vh;
    margin-top: 2px;
    margin-bottom: 0px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* number of lines to show */
  }
  .lr {
    padding: 0 2vw 0 0vw;
    width: 25%;
  }
  .location {
    width: 20%;
    @media (max-width: 768px) {
      width: 50%;
      text-align: center;
    }
  }
  .propetyType {
    width: 28%;
    @media (max-width: 768px) {
      width: 50%;
      text-align: center;
    }
  }
  .averagePrice {
    @media (max-width: 768px) {
      margin-top: 1.5vh;
      width: 50%;
      text-align: center;
    }
  }
  .searchbtn {
    width: 25%;
    display: flex;
    // background-color: #050d21;
    justify-content: flex-end;
    // align-items: flex-end;
    // flex-grow: 1;
    @media (max-width: 768px) {
      margin-top: 1.5vh;
      width: 50%;
      text-align: center;
      justify-content: center;
    }
  }
  .fill-space {
    display: inline-flex;
    flex-grow: 100;
  }
  .iconify {
    color: var(--primaryColor);
    margin-left: 2px;
    margin-bottom: 1.5px;
    font-size: 1.2vh;
  }
  .btn {
    background: var(--primaryColor);
    color: white;
    border-radius: 3vh;
    font-size: 1.8vh;
    text-align: center;
    padding: 1vh 3vh 1vh 3vh;
    font-weight: bold;
    width: 75%;
    // transition: all ease 0.5s;

    &:hover {
      border: 2px solid var(--primaryColor);
      background: transparent;
      color: var(--primaryColor);
    }
  }
}

.click {
  cursor: pointer;
}
#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 4vh;
  h1 {
    font-family: var(--fontUbuntuBD);
    color: var(--altPrimaryColor);
    font-size: 4vh;
  }
  p {
    font-family: var(--fontUbuntuLT);
    color: var(--altPrimaryColor);
    font-size: 2vh;
  }
  .info-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    border: 1px solid #f1f2f3;
    // border-right: none;
    cursor: default;
    // justify-content: center;
    align-items: center;

    &:hover {
      -webkit-box-shadow: 5px 5px 0px 0px rgba(113, 219, 119, 0.3);
      box-shadow: 5px 5px 0px 0px rgba(113, 219, 119, 0.3);
    }
    .iconify {
      margin-bottom: 12px;
      font-size: 3vh;
    }
  }
  .inner_box {
    border: 1px solid #f1f2f3;
  }
}

#properties {
  display: flex;
  justify-content: center;
  .content-area-2 {
    padding: 100px 0 70px;
  }
  .main-title {
    text-align: center;
    margin-bottom: 50px;
    h1 {
      font-family: var(--fontUbuntuBD);
      color: var(--altPrimaryColor);
      font-size: 4vh;
    }
    p {
      font-family: var(--fontUbuntuLT);
      color: var(--altPrimaryColor);
      font-size: 2vh;
    }
  }
  .property-box-8 {
    .detail {
      position: relative;
      float: left;
      width: 90%;
      margin: -30px 5% 30px 5%;
      background: #fff;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
      z-index: 4;
      h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      h6 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 15px;
        font-family: var(--fontUbuntuMD);
      }
    }
    .heading {
      padding: 20px;
    }
    .location {
      margin: 0;
      font-size: 13px;
      font-weight: 400;
      a {
        color: #535353;
      }
    }
    .date-box {
      position: absolute;
      top: 15px;
      font-size: 9px;
      color: #fff;
      text-align: center;
      right: 30px;
      letter-spacing: 1.5px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 6px 10px 4px 10px;
      border-radius: 2px;
      text-transform: uppercase;
    }
    .fav-box {
      position: absolute;
      top: 15px;
      font-size: 9px;
      color: #fff;
      text-align: center;
      left: 30px;
      letter-spacing: 1.5px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 6px 10px 4px 10px;
      border-radius: 2px;
      text-transform: uppercase;
    }
    .properties-listing {
      text-align: center;
      border-top: 1px solid #ebebeb;

      span {
        color: #5a5959;
        float: left;
        line-height: 40px;
        text-align: center;
        width: 33.3%;
        font-size: 14px;
        border-right: 1px solid #ebebeb;
        &:last-child {
          border-right: 1px solid transparent;
        }
      }
    }
  }
}

.img-fluid {
  max-width: 100% !important;
  height: auto;
}

footer {
  margin-top: auto;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: var(--fontUbuntuMD);
  color: grey;
  align-items: center;

  .muse {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 8vh;
      height: auto;
    }
  }
  .muse-body {
    text-align: right;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.fa-heart {
  color: var(--primaryColor); // #eb5e28;
  font-size: 14px;
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.scrollBtn {
  position: fixed;
  right: 20px;
  bottom: 70px;
  // bottom: 40px;
  height: 40px;
  width: 40px;
  font-size: 3rem;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  border-radius: 16px;
  transition: 0.5s ease all;

  .fa-icon {
    background: var(--primaryColor);
    padding: 12px;
    border-radius: 16px;
    color: white;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
      -5px -5px 35px rgba(254, 214, 124, 0.1);
  }
}

.flex_cen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  height: 28px;
  width: 28px;
}

.slideUp_Anim {
  animation: backInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.fadeInUp_Anim {
  display: inline-block;
  animation: fadeInUp;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}
.delay_3 {
  animation-delay: 0.3s;
}
.delay_5 {
  animation-delay: 0.5s;
}
.delay_7 {
  animation-delay: 0.7s;
}
.delay1 {
  animation-delay: 1s;
}
.delay1_5 {
  animation-delay: 1.5s;
}
.delay2 {
  animation-delay: 2s;
}

//Extra small Phones
@media (min-width: 767px) {
}

//Small Tablets
@media (max-width: 768px) {
}

//Medium Small Laptop
@media (max-width: 992px) {
}

//Large Laptop & Desktop
@media (max-width: 1200px) {
}
